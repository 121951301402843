import React from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import bestWayToKeepTrackOfCEThumbnail from "../tools/pictures/bestWayToKeepTrackOfCEThumbnail.jpg";
import addCEToLicenseThumbnail from "../tools/pictures/addCEToLicenseThumbnail.jpg";
import licenseVsCertThumbnail from "../tools/pictures/licenseVsCertThumbnail.jpg";
import addLicenseOrCertThumbnail from "../tools/pictures/addLicenseOrCertThumbnail.jpg";
import bestFeaturesOfCETrackerThumbnail from "../tools/pictures/bestFeaturesOfCETrackerThumbnail.jpg";
import nurseBurnoutThumbnail from "../tools/pictures/nurseBurnoutThumbnail.jpg";
import blueBlank from "../tools/pictures/blueBlank.png";
import file from "../tools/pictures/file.png";

export const Blog = () => {
  return (
    <div>
      <Navbar1 />
      <div className="pb-5">
        <Container fluid style={containerFormat}>
          <Row style={featureRowFormat1}>
            <Col lg={{ offset: 1 }}>
              <h4>Most Recent Articles</h4>
            </Col>
          </Row>
          <Row style={featureRowFormat2}>
            <Col
              sm={12}
              lg={{ span: 6, offset: 1 }}
              style={{ padding: "0 0 0 0" }}
            >
              <Link
                to="/BestWayToKeepTrackOfCEs"
                alt="feature story"
                style={featureFormat}
              >
                <img
                  src={bestWayToKeepTrackOfCEThumbnail}
                  alt="file folders"
                  style={{ width: "100%", height: "auto" }}
                ></img>
                <p style={featureCategoryStyle}>Workplace</p>
                <p style={featureTitleStyle}>
                  What is the best way to keep track of my CEs?
                </p>
              </Link>
            </Col>
            <Col lg={4} style={{ padding: "0em 2em 0em 2em" }}>
              <Row>
                <Col sm={6} lg={9}>
                  <Link
                    to="/AddCEToLicense"
                    alt="side feature story"
                    style={sideFeatureFormat}
                  >
                    <img
                      src={addCEToLicenseThumbnail}
                      alt="picture"
                      style={{ width: "100%", height: "auto" }}
                    ></img>
                    <p style={sideFeatureCategoryStyle}>How to</p>
                    <p style={sideFeatureTitleStyle}>
                      How to add a CE and apply it to a license
                    </p>
                  </Link>
                </Col>
                <Col sm={6} lg={9}>
                  <Link
                    to="/LicenseVsCertification"
                    alt="second side feature story"
                    style={sideFeatureFormat}
                  >
                    <img
                      src={licenseVsCertThumbnail}
                      alt="picture"
                      style={{ width: "100%", height: "auto" }}
                    ></img>
                    <p style={sideFeatureCategoryStyle}>Workplace</p>
                    <p style={sideFeatureTitleStyle}>
                      Difference between a License and Certification
                    </p>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container fluid className="pt-4">
          <Row style={articleRowFormat1}>
            <Col lg={{ offset: 1 }}>
              <h4 className="py1">Must Reads</h4>
            </Col>
          </Row>
          <Row style={articleRowFormat2}>
            <Col lg={{ span: 3, offset: 1 }}>
              <Link to="/AddLicenseOrCertification" style={thumbnailFormat}>
                <img
                  src={addLicenseOrCertThumbnail}
                  alt="article thumbnail"
                  style={thumbnailPhotoStyle}
                ></img>
                <p style={thumbnailCategoryStyle}>How to</p>
                <p style={thumbnailTitleStyle}>
                  How to add a License or Certification
                </p>
              </Link>
            </Col>
            <Col lg={3}>
              <Link to="/BestFeaturesCETracker" style={thumbnailFormat}>
                <img
                  src={bestFeaturesOfCETrackerThumbnail}
                  alt="article thumbnail"
                  style={thumbnailPhotoStyle}
                ></img>
                <p style={thumbnailCategoryStyle}>Workplace</p>
                <p style={thumbnailTitleStyle}>
                  What features should be in a continuing education tracker?
                </p>
              </Link>
            </Col>
            <Col lg={3}>
              <Link to="/NursingBurnout" style={thumbnailFormat}>
                <img
                  src={nurseBurnoutThumbnail}
                  alt="article thumbnail"
                  style={thumbnailPhotoStyle}
                ></img>
                <p style={thumbnailCategoryStyle}>Health</p>
                <p style={thumbnailTitleStyle}>Nursing Burnout</p>
              </Link>
            </Col>
          </Row>
          {/* <Row style={articleRowFormat2}>
            <Col lg={{ span: 3, offset: 1 }}>
              <Link to="/" style={thumbnailFormat}>
                <img
                  src={blueBlank}
                  alt="article thumbnail"
                  style={thumbnailPhotoStyle}
                ></img>
                <p style={thumbnailCategoryStyle}>Category</p>
                <p style={thumbnailTitleStyle}>
                  CEU Management from start to finish
                </p>
              </Link>
            </Col>
            <Col lg={3}>
              <Link to="/" style={thumbnailFormat}>
                <img
                  src={blueBlank}
                  alt="article thumbnail"
                  style={thumbnailPhotoStyle}
                ></img>
                <p style={thumbnailCategoryStyle}>Category</p>
                <p style={thumbnailTitleStyle}>How to add a license</p>
              </Link>
            </Col>
            <Col lg={3}>
              <Link to="/" style={thumbnailFormat}>
                <img
                  src={blueBlank}
                  alt="article thumbnail"
                  style={thumbnailPhotoStyle}
                ></img>
                <p style={thumbnailCategoryStyle}>Category</p>
                <p style={thumbnailTitleStyle}>Travel nursing</p>
              </Link>
            </Col>
          </Row> */}
        </Container>
      </div>
      <Footer />
    </div>
  );
};

const containerFormat = {
  paddingTop: "1em",
  border: "none",
};

const bottomBorder = {
  borderBottom: "solid",
  borderWidth: "2px",
  borderColor: "LightGrey",
};

const viewAll = {
  display: "inline",
  fontSize: "0.7em",
  textDecoration: "none",
  color: "rgb(35, 81, 133)",
  textAlign: "right",
};

const featureRowFormat1 = {
  padding: "0.5em 0em 0.5em 0em",
  marginLeft: "5em",
  marginRight: "5em",
};

const featureRowFormat2 = {
  padding: "0.5em 0em 0.5em 0em",
  marginLeft: "5em",
  marginRight: "5em",
};

const featureFormat = {
  border: "none",
  textDecoration: "none",
  color: "black",
};

const featurePicture = {
  width: 800,
  height: "100%",
  backgroundImage: `url(${blueBlank})`,
  backgroundSize: "cover",
};

const featureCategoryStyle = {
  fontSize: "0.7em",
  fontWeight: 700,
  textTransform: "uppercase",
  color: "grey",
  paddingTop: "15px",
};

const featureTitleStyle = {
  fontSize: "1em",
  fontWeight: 900,
};

const sideFeatureFormat = {
  border: "none",
  textDecoration: "none",
  color: "black",
};

const sideFeatureCategoryStyle = {
  paddingTop: "10px",
  fontSize: "0.5em",
  textTransform: "uppercase",
  fontWeight: 700,
  color: "grey",
};

const sideFeatureTitleStyle = {
  fontSize: "0.7em",
  fontWeight: 900,
};

const articleRowFormat1 = {
  padding: "0.5em 0em 0.5em 0em",
  marginLeft: "5em",
  marginRight: "5em",
};

const articleRowFormat2 = {
  padding: "0.5em 0em 0.5em 0em",
  marginLeft: "5em",
  marginRight: "5em",
};

const thumbnailFormat = {
  border: "none",
  color: "black",
  textDecoration: "none",
};

const thumbnailCategoryStyle = {
  paddingTop: "10px",
  fontSize: "0.5em",
  textTransform: "uppercase",
  fontWeight: 700,
  color: "grey",
};

const thumbnailTitleStyle = {
  fontSize: "0.7em",
  fontWeight: 900,
};

const thumbnailPhotoStyle = {
  width: "100%",
  height: "auto",
};

const descriptionFormat = {
  style: "justify-content-center",
};

export default Blog;
