import React from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";

export const Privacy = () => {
  return (
    <div>
      <Navbar1 />
      <iframe
        src="https://app.termly.io/document/privacy-policy/99dadf06-efc7-4aab-9263-309ef5342c74"
        style={borderStyle}
        title="Privacy Policy"
      ></iframe>
      <Footer />
    </div>
  );
};

const borderStyle = {
  height: "475rem",
  width: "100%",
  border: "none",
};

export default Privacy;
