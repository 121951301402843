import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import logo1 from "../tools/pictures/logo1.png";

function Navbar1() {
  return (
    <Navbar expand="lg">
      <Navbar.Brand href="/">
        <img src={logo1} alt="Logo" style={heroImage}></img>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto ml-auto">
          <NavDropdown title="Company" id="basic-nav-dropdown">
            <NavDropdown.Item href="/" style={dropdownStyle}>
              Home
            </NavDropdown.Item>
            <NavDropdown.Item href="/WhyUs" style={dropdownStyle}>
              Why Choose Us?
            </NavDropdown.Item>
            {/* <NavDropdown.Item href="/Testimonials">
              Testimonials
            </NavDropdown.Item> */}
            <NavDropdown.Item href="/Location" style={dropdownStyle}>
              Location
            </NavDropdown.Item>
            <NavDropdown.Item href="/About" style={dropdownStyle}>
              About Us
            </NavDropdown.Item>
          </NavDropdown>
          {/* <Nav.Link href="/">Home</Nav.Link> */}
          <NavDropdown title="Product" id="basic-nav-dropdown">
            <NavDropdown.Item href="/WhatWeOffer" style={dropdownStyle}>
              What We Offer
            </NavDropdown.Item>
            <NavDropdown.Item href="/Features" style={dropdownStyle}>
              Features
            </NavDropdown.Item>
            <NavDropdown.Item href="/Renew" style={dropdownStyle}>
              How does the renewal process work?
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="/Pricing">Pricing</Nav.Link>
          <Nav.Link href="/FAQs">FAQ</Nav.Link>
          <Nav.Link href="/Blog">Blog</Nav.Link>
          <Nav.Link href="/Contact">Contact Us </Nav.Link>
        </Nav>
        {/* <Link
          to="/Login"
          className="btn btn-sm"
          role="button"
          style={buttonColor}
        >
          Sign In
        </Link> */}
      </Navbar.Collapse>
    </Navbar>
  );
}

const heroImage = {
  height: "auto",
  maxWidth: "100%",
};

const buttonColor = {
  color: "#fff",
  backgroundColor: "rgb(35, 81, 133)",
  borderColor: "rgb(35, 81, 133)",
  fontSize: "1em",
};

const navFont = {
  fontSize: "1.5vw",
  color: "rgb(35, 81, 133)",
};

const dropdownStyle = {
  fontSize: "0.8em",
};

export default Navbar1;
