import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import topDiagonal1 from "../tools/pictures/topDiagonal1.svg";
import bottomDiagonal1 from "../tools/pictures/bottomDiagonal1.svg";
import fileFolder from "../tools/pictures/fileFolder.png";

export const HowItWorks = () => {
  return (
    <div>
      <Container fluid style={{ padding: "0 0 0 0 ", margin: "0 0 0 0" }}>
        <Row style={{ margin: "0 0 0 0" }}>
          <img src={topDiagonal1} alt="Top Diagonal Background Line" style={diagonalStyle}></img>
        </Row>
        <Row style={titleRowFormat}>
          <div className="container">
            <h4 style={titleFont}>Here's how it works:</h4>
          </div>
        </Row>
        <Row
          className="justify-content-md-center"
          style={stepsPictureRowFormat}
        >
          <Col sm={12} md={6} lg="2">
            <Row>
            <i className="material-icons md-48" style={icon}>
                menu_book
              </i>
              {/* This is a sample of the previous icon code
              <img
                src={fileFolder}
                alt="file folder"
                style={pictureRowImg}
              ></img> */}
            </Row>
            <Row>
              <p style={textRowFont}>Take a class</p>
            </Row>
          </Col>
          <Col sm={12} md={6} lg="2">
            <Row>
              <i className="material-icons md-48" style={icon}>
                send_to_mobile
              </i>
            </Row>
            <Row>
              <p style={textRowFont}>
                Scan a pic of your <br></br>continuing education <br></br>(CE) document with<br></br> the
                CEsimply app
              </p>
            </Row>
          </Col>
          <Col sm={12} md={6} lg="2">
            <Row>
            <i className="material-icons md-48" style={icon}>
                done_all    
              </i>
            </Row>
            <Row>
              <p style={textRowFont}>
                Apply it to a license, certification,<br></br> or both!
              </p>
            </Row>
          </Col>
          <Col sm={12} md={6} lg="2">
            <Row>
            <i className="material-icons md-48" style={icon}>
                not_listed_location    
              </i>
            </Row>
            <Row>
              <p style={textRowFont}>
                If you are a California RN or<br></br> LVN, let us renew your license<br></br> for
                you! Never log into the<br></br> BreEZe website again.<br></br>(Feature available
                for<br></br>concierge plan members.)
              </p>
            </Row>
          </Col>
        </Row>
        <Row style={{ margin: "0 0 0 0" }}>
          <img src={bottomDiagonal1} alt="Bottom Diagonal Background Line" style={diagonalStyle}></img>
        </Row>
      </Container>
    </div>
  );
};

const titleRowFormat = {
  backgroundColor: "rgb(35, 81, 133)",
  paddingBottom: "1.5em",
  margin: "0 0 0 0",
};

const titleFont = {
  color: "white",
  fontWeight: "700",
};

const diagonalStyle = {
  maxWidth: "100%",
};

const stepsPictureRowFormat = {
  margin: "0 0 0 0",
  backgroundColor: "rgb(35, 81, 133)",
  paddingLeft: "0.5em",
};

const pictureRow = {
  // borderLeftStyle: "solid",
  // borderLeftColor: "white",
  // borderLeftWidth: "1px",
  borderBottomStyle: "solid",
  borderBottomColor: "white",
  borderBottomWidth: "1.5px",
};

const pictureRowImg = {
  width: "50px",
  height: "50px",
  marginLeft: "1em",
};

const icon = {
    backgroundColor: "rgb(35, 81, 133)",
    // marginLeft: "0.4em",
    color: "white",
};

const textRowFont = {
  color: "white",
  fontSize: "0.8em",
  paddingTop: "1em",
};

export default HowItWorks;
