import React, { Fragment, useContext, useEffect } from "react";
import License from "../layout/License";

import AuthContext from "../context/auth/authContext";
import LicenseContext from "../context/license/licenseContext";

export const Dashboard = () => {
  const authContext = useContext(AuthContext);
  const licenseContext = useContext(LicenseContext);

  const onSubmit = () => {
    authContext.logout();
  };

  const { license, addLicense, getLicenseData } = licenseContext;

  //to do:  find a way to ensure that you have the updated information on the dashboard.
  // useEffect(() => {
  //   if (license) {
  //     getLicenseData();
  //   }
  // }, [license]);

  return (
    <div className="container-fluid" style={dashboardStyle}>
      <div className="row">
        <nav
          id="sidebarMenu"
          className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
        >
          <div className="sidebar-sticky pt-3">
            <ul className="nav flex-column">
              <li className="nav-item">
                <a className="nav-link active" href="#">
                  <span data-feather="home"></span>
                  Dashboard <span className="sr-only">(current)</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <span data-feather="file"></span>
                  Orders
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <span data-feather="shopping-cart"></span>
                  Products
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <span data-feather="users"></span>
                  Customers
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <span data-feather="bar-chart-2"></span>
                  Reports
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <span data-feather="layers"></span>
                  Integrations
                </a>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link bg-light"
                  onClick={onSubmit}
                  style={logoutButton}
                >
                  <span data-feather="layers"></span>
                  Sign Out
                </button>
              </li>
            </ul>

            {/* <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
              <span>Saved reports</span>
              <a
                class="d-flex align-items-center text-muted"
                href="#"
                aria-label="Add a new report"
              >
                <span data-feather="plus-circle"></span>
              </a>
            </h6>
            <ul class="nav flex-column mb-2">
              <li class="nav-item">
                <a class="nav-link" href="#">
                  <span data-feather="file-text"></span>
                  Current month
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  <span data-feather="file-text"></span>
                  Last quarter
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  <span data-feather="file-text"></span>
                  Social engagement
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  <span data-feather="file-text"></span>
                  Year-end sale
                </a>
              </li>
            </ul> */}
          </div>
        </nav>

        <main
          role="main"
          className="col-md-9 ml-sm-auto col-lg-10 px-md-4"
          style={mainStyle}
        >
          <div className="align-items-center pt-3 pb-2 mb-3">
            <div className="row mt-3 mb-4 justify-content-around">
              <button
                type="button"
                className="btn btn-primary"
                style={toolbarButton}
              >
                Home
              </button>
              <button
                type="button"
                className="btn btn-primary"
                style={toolbarButton}
              >
                Documents
              </button>
              <button
                type="button"
                className="btn btn-primary"
                style={toolbarButton}
                onClick={addLicense}
              >
                Completed
              </button>
            </div>
            <Fragment>
              {Object.keys(license).length ? (
                <div className="row px-3 py-3" style={licenseContainer}>
                  <License />
                </div>
              ) : (
                <div>You don't have any licenses!</div>
              )}
            </Fragment>
          </div>
        </main>
      </div>
    </div>
  );
};

const dashboardStyle = {
  fontSize: ".6rem",
};

const logoutButton = {
  borderStyle: "none",
};

const mainStyle = {
  backgroundColor: "rgb(35, 81, 133)",
};

const toolbarButton = {
  width: "10rem",
  backgroundColor: "white",
  color: "black",
};

const licenseContainer = {
  backgroundColor: "rgb(230, 230, 230)",
  borderRadius: "12px",
};

export default Dashboard;
