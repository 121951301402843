import React from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import addCEToLicenseHero from "../tools/pictures/addCEToLicenseHero.jpg";
import addCEToLicense1_home_phone_denotation from "../tools/pictures/addCEToLicense1_home_phone_denotation.png";
import addCEToLicense2_licenseDetails_phone_denotation from "../tools/pictures/addCEToLicense2_licenseDetails_phone_denotation.png";
import addCEToLicense3_ceDetails_phone from "../tools/pictures/addCEToLicense3_ceDetails_phone.png";
import addCEToLicense3_ceDetails_phone_denotation from "../tools/pictures/addCEToLicense3_ceDetails_phone_denotation.png";
import addCEToLicense4_otherCredentials_phone_denotation from "../tools/pictures/addCEToLicense4_otherCredentials_phone_denotation.png";
import addCEToLicense5_otherCredentialsPopup from "../tools/pictures/addCEToLicense5_otherCredentialsPopup.png";
import addCEToLicense6_save_phone_denotation from "../tools/pictures/addCEToLicense6_save_phone_denotation.png";
import addCEToLicense7_homeComplete_phone_denotation from "../tools/pictures/addCEToLicense7_homeComplete_phone_denotation.png";
import addCEToLicense8_licenseDetailsComplete_phone_denotation from "../tools/pictures/addCEToLicense8_licenseDetailsComplete_phone_denotation.png";

export const AddCEToLicense = () => {
  const howToStructuredData = {
    "@context": "https://schema.org",
    "@type": "HowTo",
    name: "How to add a continuing education (CE) certificate to a license in CEsimply continuing education tracking app",
    step: [
      {
        "@type": "HowToStep",
        name: "Add a continuing education (CE) certificate",
        text: "The first step is to click 'Add CE'. That can be done either on the main page or the license details page after you click on a license to view its details.",
        image:
          "https://cesimply.com/blogPictures/howTo/AddCEToLicense/addCEToLicense1_home_phone_denotation.png",
      },
      {
        "@type": "HowToStep",
        name: "Enter continuing education (CE) details",
        text: "After you click on 'Add CE', enter all the details of this CE.",
        image:
          "https://cesimply.com/blogPictures/howTo/AddCEToLicense/addCEToLicense3_ceDetails_phone_denotation.png",
      },
      {
        "@type": "HowToStep",
        name: "Add a photo (optional)",
        text: "You can add a photo of the CE if you like by clicking on the camera icon under 'CE Photo (optional)'.",
        image:
          "https://cesimply.com/blogPictures/howTo/AddCEToLicense/addCEToLicense3_ceDetails_phone.png",
      },
      {
        "@type": "HowToStep",
        name: "Apply CE to other licenses (if applicable)",
        text: "If you would like this CE to be applied to any other licenses or certifications, click 'Apply to Other Credentials'.",
        image:
          "https://cesimply.com/blogPictures/howTo/AddCEToLicense/addCEToLicense4_otherCredentials_phone_denotation.png",
      },
      {
        "@type": "HowToStep",
        name: "Determine how many hours this CE will apply",
        text: "Determine how many hours this CE will count for each license or certification. Then click 'Done'.",
        image:
          "https://cesimply.com/blogPictures/howTo/AddCEToLicense/addCEToLicense5_otherCredentialsPopup.png",
      },
      {
        "@type": "HowToStep",
        name: "Save",
        text: "nce done, click 'save'.",
        image:
          "https://cesimply.com/blogPictures/howTo/AddCEToLicense/addCEToLicense6_save_phone_denotation.png",
      },
      {
        "@type": "HowToStep",
        name: "Verify your continuing education (CE)",
        text: "Verify your CEs have been applied to your license or certification. The progress bar should now be partially filled with your newly added CE. To see which CEs are being applied to the license in the future, click on the license card.",
        image:
          "https://cesimply.com/blogPictures/howTo/AddCEToLicense/addCEToLicense7_homeComplete_phone_denotation.png",
      },
      {
        "@type": "HowToStep",
        name: "Requirements under License Details",
        text: "Any CEs applied wil be visible under 'Requirements' when you click on the license to view more details.",
        image:
          "https://cesimply.com/blogPictures/howTo/AddCEToLicense/AddCEToLicense8_licenseDetailsComplete_phone_denotation.png",
      },
    ],
  };

  const JSONschema = JSON.stringify(howToStructuredData);

  return (
    <div>
      <Helmet>
        <title>
          Apply CE to license - Continuing Education Tracker | CEsimply
        </title>
        <meta
          name="description"
          content="How to add a continuing education certificate and add it to a license in CEsimply continuing education tracker."
        />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>

      <Navbar1 />
      <div className="pb-5">
        <Container>
          <p>
            <Link to="/Blog" style={backFormat}>
              Back
            </Link>
          </p>
          <Row>
            <Col style={{ padding: "0 0 0 0" }}>
              <img
                src={addCEToLicenseHero}
                alt="desk with papers"
                style={{
                  width: "100%",
                  height: "auto",
                }}
              ></img>
            </Col>
          </Row>
          <Row>
            <p style={titleStyle}>How to add CE and apply it to a license</p>
            <p style={subheaderStyle2}>
              Now that you have a license added in the app, now it’s time to add
              a continuing education unit (CE) to it. You can add a CE to your
              account without applying it to the license as well. You would do
              this if you want to just put it in the app for safe keeping but
              not apply it to any licenses. If you want to know how to do that,
              please click here. This blog post is to teach you how to add a CE
              and have it count towards your license continuing education
              requirements.
            </p>
          </Row>
          <Row>
            <p style={subheaderStyle2}>
              The first step is to click “Add CE”. That can be done either on
              the main page or the license details page after you click on a
              license to view its details.
            </p>
          </Row>
          <Row>
            <img
              src={addCEToLicense1_home_phone_denotation}
              alt="home screen"
              style={{
                width: "25%",
                height: "auto",
                margin: "auto",
              }}
            ></img>
            <img
              src={addCEToLicense2_licenseDetails_phone_denotation}
              alt="home screen"
              style={{
                width: "25%",
                height: "auto",
                margin: "auto",
              }}
            ></img>
          </Row>
          <Row className="justify-content-center">
            <p style={subheaderStyle2}>
              Main page (on the left) and License details page (on the right)
            </p>
          </Row>
          <Row>
            <p style={subheaderStyle2}>
              *Please note, this CE will be automatically applied to the license
              that you clicked “Add CE” on. In the screenshot above, this CE
              were adding will be automatically applied to the RN license (CA).{" "}
            </p>
          </Row>
          <Row>
            <img
              src={addCEToLicense3_ceDetails_phone}
              alt="home screen"
              style={{
                width: "25%",
                height: "auto",
                margin: "auto",
                paddingTop: "4em",
              }}
            ></img>
          </Row>
          <Row className="justify-content-center">
            <p style={subheaderStyle2}>
              After you click on "Add CE", enter all the details of this CE.
            </p>
          </Row>
          <Row>
            <img
              src={addCEToLicense3_ceDetails_phone_denotation}
              alt="home screen"
              style={{
                width: "25%",
                height: "auto",
                margin: "auto",
                paddingTop: "4em",
              }}
            ></img>
          </Row>
          <Row className="justify-content-center">
            <p style={subheaderStyle2}>
              You can add a photo of the CE if you like by clicking on the
              camera icon under "CE Photo (optional)".
            </p>
          </Row>
          <Row>
            <img
              src={addCEToLicense4_otherCredentials_phone_denotation}
              alt="home screen"
              style={{
                width: "25%",
                height: "auto",
                margin: "auto",
                paddingTop: "4em",
              }}
            ></img>
          </Row>
          <Row className="justify-content-center">
            <p style={subheaderStyle2}>
              If you would like this CE to be applied to any other licenses or
              certifications, click “Apply to Other Credentials”.
            </p>
          </Row>
          <Row>
            <img
              src={addCEToLicense5_otherCredentialsPopup}
              alt="home screen"
              style={{
                width: "25%",
                height: "auto",
                margin: "auto",
                paddingTop: "4em",
              }}
            ></img>
          </Row>
          <Row className="justify-content-center">
            <p style={subheaderStyle2}>
              Determine how many hours this CE will count for each license or
              certification. Then click "Done".
            </p>
          </Row>
          <Row>
            <img
              src={addCEToLicense6_save_phone_denotation}
              alt="home screen"
              style={{
                width: "25%",
                height: "auto",
                margin: "auto",
                paddingTop: "4em",
              }}
            ></img>
          </Row>
          <Row className="justify-content-center">
            <p style={subheaderStyle2}>Once done, click "save".</p>
          </Row>
          <Row>
            <img
              src={addCEToLicense7_homeComplete_phone_denotation}
              alt="home screen"
              style={{
                width: "25%",
                height: "auto",
                margin: "auto",
                paddingTop: "4em",
              }}
            ></img>
          </Row>
          <Row className="justify-content-center">
            <p style={subheaderStyle2}>
              Verify your CEs have been applied to your license or
              certification. The progress bar should now be partially filled
              with your newly added CE. To see which CEs are being applied to
              the license in the future, click on the license card.
            </p>
          </Row>
          <Row>
            <img
              src={addCEToLicense8_licenseDetailsComplete_phone_denotation}
              alt="home screen"
              style={{
                width: "25%",
                height: "auto",
                margin: "auto",
                paddingTop: "4em",
              }}
            ></img>
          </Row>
          <Row className="justify-content-center">
            <p style={subheaderStyle2}>
              Any CEs applied wil be visible under "Requirements" when you click
              on the license to view more details.
            </p>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

const backFormat = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "500",
};

const linkFormat = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "700",
};

const titleStyle = {
  paddingTop: "2em",
  fontWeight: "700",
};

const subheaderStyle1 = {
  fontSize: "0.9em",
};

const subheaderStyle2 = {
  fontSize: "0.8em",
  paddingTop: "1em",
};

const paragraphStyle = {
  fontSize: "0.7em",
};

export default AddCEToLicense;
