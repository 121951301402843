import React, { useReducer, useEffect } from "react";
import AuthContext from "./authContext";
import authReducer from "./authReducer";
import firebase from "firebase/app";
import "firebase/auth";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
} from "../types";

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: null,
    loading: true,
    user: {},
    error: {},
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  // Logged in User Listener
  function onAuthStateChanged(user) {
    if (user) {
      dispatch({
        type: USER_LOADED,
        payload: user,
      });
      //figure out how to push to dashboard
      // history.push("/Dashboard");
      console.log(`user is ${JSON.stringify(user)}`);
    } else {
      dispatch({
        type: AUTH_ERROR,
      });
      console.log("user authentication failed");
    }
  }

  useEffect(() => {
    const subscriber = firebase.auth().onAuthStateChanged(onAuthStateChanged);
    return subscriber; // unsubscribe on unmount
  }, []);

  //Register User
  //Put an alert that the user register was successful
  const register = (email, password) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(function () {
        dispatch({
          type: REGISTER_SUCCESS,
          //is this data line really needed?
          // data: { userEmail: email, userPassword: password },
        });
        console.log("user account created");
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;

        dispatch({
          type: REGISTER_FAIL,
          payload: error,
        });
        console.log("user sign up didn't work");
        console.log(`${errorCode}`);
        console.log(`${errorMessage}`);
      });
  };

  //Login User
  const login = (email, password) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(function () {
        dispatch({
          type: LOGIN_SUCCESS,
        });
        console.log("user logged in successfully");
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;

        dispatch({ type: LOGIN_FAIL, payload: error });
        console.log(`${errorCode}`);
        console.log(`${errorMessage}`);
      });
  };

  //Logout
  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        dispatch({ type: LOGOUT });
        console.log("user logged out successfully");
      })
      .catch(function (error) {
        console.log("user logout failed");
      });
  };

  //Clear Errors

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        isLoggedIn: state.isLoggedIn,
        loading: state.loading,
        user: state.user,
        error: state.error,
        register,
        login,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;

//This is sample of how to use variable with $ and "backticks"
// console.log(`Email is: ${email}`);
