import React from "react";
import { Link } from "react-router-dom";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";
import mobile from "../tools/pictures/mobile.png";
import mobileScanning from "../tools/pictures/mobileScanning.png";
import unlimitedStorage from "../tools/pictures/unlimitedStorage.png";
import applyCEs from "../tools/pictures/applyCEs.png";
import exportCEs from "../tools/pictures/exportCEs.png";
import licenseRenewal from "../tools/pictures/licenseRenewal.png";
import wave2 from "../tools/pictures/wave2.svg";

export const Features = () => {
  return (
    <div>
      <Navbar1 />
      <div className="container-fluid" style={leadingBox}>
        <div class="row justify-content-center">
          <h2 style={leadingTitle}>Features</h2>
        </div>
        <div class="row justify-content-center">
          <h5 style={{ color: "white", textAlign: "center", fontSize: "1em" }}>
            CE management from start through submission
          </h5>
        </div>
        {/* <div class="row justify-content-center">
          <Link
            to="/Signup"
            className="btn btn-sm btn-light"
            style={{ fontSize: "1em" }}
          >
            Sign Up
          </Link>
        </div> */}
      </div>
      <img src={wave2} alt="wave pattern"></img>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-4 order-1 order-md-1" style={pictureBox}>
            <img
              src={mobileScanning}
              alt="mobile"
              style={{ height: "auto", width: "100%" }}
            ></img>
          </div>
          <div className="col-md-5 order-2 order-md-2" style={descriptionBox}>
            <h4 style={descriptionHeader}>Mobile Scanning</h4>
            <p>
              Take an image of your CE certificate right when you walk out of
              class.
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-5 order-4 order-md-3" style={descriptionBox}>
            <h4 style={descriptionHeader}>Unlimited Storage</h4>
            <p>
              Never worry about searching for documents when you are audited. We
              will hold on to them for as long as you’d like us to!
            </p>
          </div>
          <div className="col-md-4 order-3 order-md-4" style={pictureBox}>
            <img src={unlimitedStorage} alt="mobile" style={pictureStyle}></img>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-4 order-5 order-md-5" style={pictureBox}>
            <img src={applyCEs} alt="mobile" style={pictureStyle}></img>
          </div>
          <div className="col-md-5 order-6 order-md-6" style={descriptionBox}>
            <h4 style={descriptionHeader}>Apply CEs</h4>
            <p>
              Easily sync your CE to one or more licenses and/or certifications.
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-5 order-8 order-md-7" style={descriptionBox}>
            <h4 style={descriptionHeader}>Reminders</h4>
            <p>
              Get notifications for upcoming license and certificate renewals.
            </p>
          </div>
          <div className="col-md-4 order-7 order-md-8" style={pictureBox}>
            <img src={mobile} alt="mobile" style={pictureStyle}></img>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-4 order-9 order-md-9" style={pictureBox}>
            <img src={exportCEs} alt="mobile" style={pictureStyle}></img>
          </div>
          <div className="col-md-5 order-10 order-md-10" style={descriptionBox}>
            <h4 style={descriptionHeader}>Export</h4>
            <p>
              Effortlessly send previously saved CE documents to your email at
              any time.
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-5 order-12 order-md-11" style={descriptionBox}>
            <h4 style={descriptionHeader}>
              Submit License Renewal Through Us!
            </h4>
            <p>
              Tired of typing out all the CE details for every class you took?
              Let us submit your renewal so you won’t have to waste your time
              doing it ever again.
            </p>
          </div>
          <div className="col-md-4 order-11 order-md-12" style={pictureBox}>
            <img src={licenseRenewal} alt="mobile" style={pictureStyle}></img>
          </div>
        </div>
        <div className="row justify-content-center px-3 pt-5">
          <p style={{ fontSize: "0.8em", fontWeight: "700" }}>
            Start tracking your CEs today by downloading
          </p>
        </div>
        <div className="row justify-content-center pb-5">
          <a
            href="https://apps.apple.com/us/app/cesimply/id1582382064?itsct=apps_box_badge&amp;itscg=30200"
            style={appleButton}
          >
            <img
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1630281600&h=8bd2c7e704bd2fe62db5aa45a0be6168"
              alt="Download on the App Store"
              style={appleImgStyle}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.cetracker&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            style={playButton}
          >
            <img
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              style={playImgStyle}
            />
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const leadingBox = {
  backgroundColor: "rgb(35, 81, 133)",
  padding: "6rem 5rem 2rem",
};

const leadingTitle = {
  color: "white",
  fontSize: "1.5em",
};

const pictureStyle = {
  height: "auto",
  width: "75%",
  paddingLeft: "25%",
};

const descriptionHeader = {
  color: "rgb(35, 81, 133)",
  fontWeight: "700",
};

const descriptionBox = {
  padding: "2rem 1.5rem",
  textAlign: "center",
};

const pictureBox = {
  padding: "2rem 1.5rem",
};

const appleButton = {
  display: "inline-block",
  overflow: "hidden",
  borderRadius: "13px",
};

const appleImgStyle = {
  borderRadius: "13px",
  width: "250px",
  height: "auto",
  padding: "20px",
};

const playButton = {
  display: "inline-block",
  overflow: "hidden",
  borderRadius: "13px",
};

const playImgStyle = {
  borderRadius: "13px",
  width: "243px",
  height: "110px",
  paddingTop: "5px",
};

export default Features;

// original padding for desktop
// const descriptionBox = {
//   padding: "6rem 10rem",
// };

// const pictureBox = {
//   padding: "6rem 1.5rem",
// };
