import React from "react";
import { Link } from "react-router-dom";
import hero from "../tools/pictures/hero.png";

export const Hero = () => {
  return (
    <div className="container-fluid pl-lg-5">
      <div className="row align-items-center">
        <div className="col-md-5 pt-5 pl-6" style={{ textAlign: "center" }}>
          {/* <h3 style={heroTitle}>Store your nursing CEUs forever.</h3> */}
          <h4 style={{ fontWeight: "500" }}>
            Store your nursing CEUs forever.
          </h4>
          <br></br>
          <h3 style={heroTitle}>Then let us renew your license for you.</h3>
          {/* <h4 style={{ fontWeight: "500" }}>
            Then let us renew your license for you.
          </h4> */}
          <p>Never log into the Board of Registered Nursing website again.*</p>
          {/* <Link
            to="/Signup"
            role="button"
            className="btn btn-sm"
            style={buttonColor}
          >
            Sign up
          </Link> */}
          <div className="row justify-content-center">
            <a
              href="https://apps.apple.com/us/app/cesimply/id1582382064?itsct=apps_box_badge&amp;itscg=30200"
              style={appleButton}
            >
              <img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1630281600&h=8bd2c7e704bd2fe62db5aa45a0be6168"
                alt="Download on the App Store"
                style={appleImgStyle}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.cetracker&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              style={playButton}
            >
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                style={playImgStyle}
              />
            </a>
          </div>
          <br></br>
          <br></br>
          <p style={{ fontSize: "0.5em" }}>
            *available for RNs and LVNs in California who are also Concierge Plan Members.
          </p>
        </div>
        <div className="col-md-6 pl-lg-5">
          <img src={hero} alt="Logo" style={pictureStyle}></img>
        </div>
      </div>
    </div>
  );
};

const heroTitle = {
  color: "rgb(35, 81, 133)",
  fontWeight: "700",
};

const pictureStyle = {
  height: "auto",
  width: "100%",
};

const buttonColor = {
  color: "#fff",
  backgroundColor: "rgb(35, 81, 133)",
  borderColor: "rgb(35, 81, 133)",
  fontSize: "inherit",
};

const appleButton = {
  display: "inline-block",
  overflow: "hidden",
  borderRadius: "13px",
};

const appleImgStyle = {
  borderRadius: "13px",
  width: "250px",
  height: "auto",
  padding: "20px",
};

const playButton = {
  display: "inline-block",
  overflow: "hidden",
  borderRadius: "13px",
};

const playImgStyle = {
  borderRadius: "13px",
  width: "243px",
  height: "110px",
  paddingTop: "5px",
};

export default Hero;
