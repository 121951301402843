import React from "react";
import { Link } from "react-router-dom";
import logo2 from "../tools/pictures/logo2.png";

export const Footer = () => {
  return (
    <div className="container-fluid" style={footerStyle}>
      <div className="row justify-content-center">
        <img src={logo2} alt="Logo" className="pt-lg-1"></img>
      </div>
      <div className="row py-lg-2 justify-content-center">
        <Link to="/About" style={titleStyle}>
          About us
        </Link>
        <Link to="/Privacy" style={titleStyle}>
          Privacy Policy
        </Link>
        <Link to="/Terms" style={titleStyle}>
          Terms of Service
        </Link>
        <Link to="/Refund" style={titleStyle}>
          Refund Policy
        </Link>
        <Link to="/Contact" style={titleStyle}>
          Contact Us
        </Link>
        <Link to="/Disclaimer" style={titleStyle}>
          Disclaimer
        </Link>
        {/* <button
          className="termly-cookie-preference-button"
          type="button"
          style={cookieStyle}
          onclick="displayPreferenceModal()"
        >
          Manage Cookie Preferences
        </button> */}
      </div>
      <div className="row pl-1 pr-1 pt-2">
        <div>
          <div className="col-sm">
            <p style={{ fontSize: "0.5em", color: "white" }}>
              2020  ©. CEsimply. All rights reserved.
            </p>
          </div>
        </div>
        {/* {window.screen.width < 768 ? (
          <div>
            <div className="col-sm">
              <p style={{ fontSize: "0.5em", color: "white" }}>
                2020  ©. CEsimply. All rights reserved.
              </p>
            </div>
            <div className="col-sm">
              <div>
                <button className="btn btn-dark btn-sm">Placeholder</button>
                <button className="btn btn-light btn-sm pl-2">
                  Placeholder
                </button> */}
        {/* Previous buttons below */}
        {/* <button type="button" className="btn btn-dark btn-sm">
                  <i className="fab fa-apple"></i> Download
                </button>
                <button
                  type="button"
                  className="btn btn-outline-light btn-sm pl-2"
                >
                  <i className="fab fa-google-play"></i> Download
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="col-sm">
              <p style={{ fontSize: "0.5em", color: "white" }}>
                2020  ©. CEsimply. All rights reserved.
              </p>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Footer;

const footerStyle = {
  backgroundColor: "rgb(35, 81, 133)",
};

const titleStyle = {
  color: "white",
  alignText: "center",
  textDecoration: "none",
  paddingLeft: "1rem",
  paddingRight: "1rem",
  fontSize: "0.6em",
};

// const cookieStyle = {
//   border: "none",
//   color: "white",
//   alignText: "center",
//   backgroundColor: "rgb(35, 81, 133)",
//   fontSize: "0.6em",
// };
