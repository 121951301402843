import React from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";
import { Link } from "react-router-dom";

export const Renew = () => {
  return (
    <div>
      <Navbar1 />
      <div className="container pb-5">
        <div className="row justify-content-center py-4">
          <h4>How does the renewal process work?</h4>
        </div>
        <p style={fontStyle}>
          For starters, this service is only available for our Concierge Plan
          members.
        </p>
        <p style={fontStyle}>
          Once your CE requirements have been completed and you are within 3
          months of your license expiring, you will be able to click the button
          that says “Start Renewal” in the app.
        </p>
        <p style={fontStyle}>
          In order for us to submit your renewal with the Board of Registered
          Nursing, you must share your BRN login information with us. This is an
          important step as a name along does not provide enough positive user
          identification. Your security is a top priority for us. We will treat
          your login information as if it was our own (after all, we're nurses
          ourselves!). To see our privacy policy, please click{" "}
          <Link to="/Privacy" style={linkFormat}>
            here
          </Link>
          .
        </p>
        <p style={fontStyle}>
          You will then answer the questions that the BRN asks during renewal
          within the app. We will save your responses so that next time around
          you will only need to verify whether or not the answers have changed.
        </p>
        <p style={fontStyle}>
          We will then auto-fill all the CE details you have been collecting in
          the CEsimply app (e.g., the name, number of hours, date taken,
          provider number, and image for each CE document) to the renewal site.
        </p>
        <p style={fontStyle}>
          You can review all of your answers and CEs prior to clicking submit.
          Once you submit, you will no longer be able to change any of your
          answers.
        </p>
        <p style={fontStyle}>
          After submission and your renewal has been accepted, you will receive
          an email confirmation directly from the BRN within 1-24 hours. The
          email they will use is the one you have on file with them. You will
          also receive and email from us, a summary pdf of the answers and CEs
          we submitted for you.
        </p>
        <p style={fontStyle}>
          All of your CEs will be kept forever in case you get audited or need
          to reference them again.
        </p>
        <p style={fontStyle}>
          You are still responsible for the license renewal fee from your
          licensing board. During the submission process in the app, you will be
          asked to enter a credit/debit card to pay this fee.
        </p>
        <p style={fontStyle}>
          If your submission with the BRN encounters an issue of any kind, ie
          missing data, not to worry! Someone on our team will be notified and
          you will be contacted (please make sure you have access to the email address you used to create an account with for the app). Please allow up to 3 business days for us
          to contact you. Because of this, we recommend renewing your license at
          least 1 week prior to the expiration date.
        </p>
        <p style={fontStyle}>
          That's it! As mentioned before, once you've renewed once with us, any
          future renewals is as simple as double checking your previous answers
          and submitting all your CEs again with one click.
        </p>
      </div>
      <Footer />
    </div>
  );
};

const fontStyle = {
  fontSize: "0.8em",
};

const linkFormat = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "900",
};

export default Renew;
