import React from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";

export const Terms = () => {
  return (
    <div>
      <Navbar1 />
      <iframe
        src="https://app.termly.io/document/terms-of-use-for-website/f62d54c0-5239-4826-9198-2df0c1893a33"
        style={borderStyle}
        title="Terms of Service"
      ></iframe>
      <Footer />
    </div>
  );
};

const borderStyle = {
  height: "407rem",
  width: "100%",
  border: "none",
};

export default Terms;
