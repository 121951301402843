import React from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";

export const Disclaimer = () => {
  return (
    <div>
      <Navbar1 />
      <iframe
        src="https://app.termly.io/document/disclaimer/f87efeca-cfbb-4ca7-b326-22d943702854"
        style={borderStyle}
        title="Disclaimer Statements"
      ></iframe>
      <Footer />
    </div>
  );
};

const borderStyle = {
  height: "45em",
  width: "100%",
  border: "none",
};

export default Disclaimer;
