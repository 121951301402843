import {
  LOAD_LICENSE,
  ADD_LICENSE,
  DELETE_LICENSE,
  UPDATE_LICENSE,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case LOAD_LICENSE:
      return {
        ...state,
        license: action.payload,
      };
    case ADD_LICENSE:
      return {
        ...state,
        redirect: "/Dashboard",
      };
  }
};
