import React from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import licenseVsCertHero from "../tools/pictures/licenseVsCertHero.jpg";
import blueBlank from "../tools/pictures/blueBlank.png";

export const BestWayToKeepTrackOfCEs = () => {
  const articleStructuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: "What is the difference between License and Certification?",
    image: "https://cesimply.com/pictures/blogPictures/licenseVsCertHero.jpg",
    datePublished: "2022-03-16T08:00:11+00:00",
    dateModified: "2022-04-12T09:20:00+08:00",
    author: {
      "@type": "Person",
      name: "Michael Hwang",
    },
  };

  const JSONschema = JSON.stringify(articleStructuredData);

  return (
    <div>
      <Helmet>
        <title>
          License vs Certification - Continuing Education | CEsimply
        </title>
        <meta
          name="description"
          content="What is the difference between a License and Certification?"
        />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>

      <Navbar1 />
      <div className="pb-5">
        <Container>
          <p>
            <Link to="/Blog" style={backFormat}>
              Back
            </Link>
          </p>
          <Row>
            <Col style={{ padding: "0 0 0 0" }}>
              <img
                src={licenseVsCertHero}
                alt="desk with papers"
                style={{
                  width: "100%",
                  height: "auto",
                }}
              ></img>
            </Col>
          </Row>
          <Row>
            <p style={titleStyle}>
              Difference between License and Certification
            </p>
            <p style={paragraphStyle}>
              The difference between a license and certification is a license is
              usually managed by a governmental entity such as the state or
              federal government while a certification is generally managed by a
              private organization. Both generally require some proof of
              competency to obtain and continuing education to uphold. Examples
              of licenses that are issued by the state are architects,
              accountants, contractors, nurses, lawyers and doctors. Obtaining a
              license to practice in order to practice a profession is
              mandatory. In many states there are laws that apply criminal
              penalties if one is found to be practicing without a license.
            </p>
            <p style={paragraphStyle}>
              A certification on the other hand usually implies a level of
              competency in their field if acquired. An example of this is the
              Basic Life Support (BLS) certification by the American Heart
              Association. Once acquired, it demonstrates a level of competency
              and the ability to perform a specific set of skills.
              Certifications may need to be renewed after a specific amount of
              time and may also require continuing education to continue to
              possess the certification. Some even require direct job hours to
              maintain.
            </p>
            <p style={paragraphStyle}>
              Because the differences between the two are small, some people may
              confuse the two. However whether or not it requires continuing
              education or who issued it, both can be stored in the CEsimply
              app. CEsimply puts it all in one place and you can see at-a-glance
              how many you've already taken. Try it out today for free.
            </p>
          </Row>
          <Row>
            <div
              className="row justify-content-center py-5"
              style={{ margin: "auto" }}
            >
              <a
                href="https://apps.apple.com/us/app/cesimply/id1582382064?itsct=apps_box_badge&amp;itscg=30200"
                style={appleButton}
              >
                <img
                  src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1630281600&h=8bd2c7e704bd2fe62db5aa45a0be6168"
                  alt="Download on the App Store"
                  style={appleImgStyle}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.cetracker&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                style={playButton}
              >
                <img
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  style={playImgStyle}
                />
              </a>
            </div>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

const backFormat = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "500",
};

const linkFormat = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "700",
};

const titleStyle = {
  paddingTop: "2em",
  fontWeight: "700",
};

const subheaderStyle1 = {
  fontSize: "0.9em",
};

const subheaderStyle2 = {
  fontSize: "0.8em",
};

const paragraphStyle = {
  fontSize: "0.7em",
};

const appleButton = {
  display: "inline-block",
  overflow: "hidden",
  borderRadius: "13px",
};

const appleImgStyle = {
  borderRadius: "13px",
  width: "250px",
  height: "auto",
  padding: "20px",
};

const playButton = {
  display: "inline-block",
  overflow: "hidden",
  borderRadius: "13px",
};

const playImgStyle = {
  borderRadius: "13px",
  width: "243px",
  height: "110px",
  paddingTop: "5px",
};

export default BestWayToKeepTrackOfCEs;
