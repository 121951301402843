import React, { useState, useContext } from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";
import AlertContext from "../context/alert/alertContext";
import opened from "../tools/pictures/opened.png";
import firebase from "firebase/app";
import "firebase/firestore";

export const Contact = () => {
  const alertContext = useContext(AlertContext);

  const { setAlert } = alertContext;

  const [contactFormContents, setContactFormContents] = useState({
    name: "",
    email: "",
    message: "",
  });

  const { name, email, message } = contactFormContents;

  const onChange = (e) =>
    setContactFormContents({
      ...contactFormContents,
      [e.target.name]: e.target.value,
    });

  const sendEmail = (contactFormContents) => {
    var emailSender = firebase.functions().httpsCallable("emailSender");

    if (name == false) {
      setAlert("Please fill in a Name", "warning");
    } else if (email == false) {
      setAlert(
        "Please fill in an email, we will be using this to respond to your question.",
        "warning"
      );
    } else if (message == false) {
      setAlert("Please type in a message", "warning");
    } else {
      setAlert(
        "Form submitted successfully!  We will contact you shortly.",
        "success"
      );
      emailSender(contactFormContents)
        .then((result) => {
          console.log(JSON.stringify(result));
        })
        .catch((error) => {
          console.log("you have an error foo");
        });
      setContactFormContents({
        ...contactFormContents,
        ["name"]: "",
        ["email"]: "",
        ["message"]: "",
      });
    }
  };

  return (
    <div>
      <Navbar1 />
      <div className="container-fluid" style={{ marginBottom: "3rem" }}>
        <div className="row justify-content-center py-4">
          <h4>Got a question?</h4>
        </div>
        <div className="row" style={{ padding: "1rem" }}>
          <div className="col-md-4">
            <img src={opened} alt="envelope" style={contactPhoto}></img>
            <p style={{ textAlign: "center" }}>
              All messages are sent to support@CEsimply.com. Please allow us 2-3
              business days to reply to your email.
            </p>
          </div>
          <form className="col-md-8" style={contactForm}>
            <div className="row form-row">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your name"
                  style={{ fontSize: "0.7em" }}
                  onChange={onChange}
                  name="name"
                  value={name}
                ></input>
              </div>
              <div className="col">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email address"
                  style={{ fontSize: "0.7em" }}
                  onChange={onChange}
                  name="email"
                  value={email}
                ></input>
              </div>
            </div>
            <div className="row form-row">
              <div className="col">
                <textarea
                  className="form-control"
                  placeholder="Type your message here"
                  rows="8"
                  style={{ fontSize: "0.7em" }}
                  onChange={onChange}
                  name="message"
                  value={message}
                ></textarea>
              </div>
            </div>
            <div className="row form-row justify-content-center">
              <button
                type="button"
                className="btn btn-sm"
                style={buttonColor}
                onClick={() => {
                  sendEmail(contactFormContents);
                }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const contactPhoto = {
  height: "auto",
  width: "100%",
};

const contactForm = {
  backgroundColor: "rgba(208, 233, 251, 1)",
  borderRadius: "15px",
};

const buttonColor = {
  color: "rgb(35, 81, 133)",
  backgroundColor: "white",
  borderColor: "rgb(35, 81, 133)",
};

export default Contact;
