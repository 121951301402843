import React, { useContext, useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/firestore";

import AuthContext from "../context/auth/authContext";

//to do:
//Figure out how to retrieve URL of photo selected when clicked on in the app.
//Pass in the email address
//Pass in file name

export const Testing = () => {
  const authContext = useContext(AuthContext);

  const { user } = authContext;

  const [CE, setCE] = useState([]);
  const [specificCE, setSpecificCE] = useState(null);

  const exportHandler = () => {
    var storage = firebase.storage();
    var pathReference = storage.ref();
    var imageRef = pathReference.child(
      "userImages/6aKT90OHXaekvltHIvPLOxlVSTv1/6cfef1e9-4c89-4bfd-94b1-9f9b4a78d580.jpeg"
    );

    imageRef
      .getDownloadURL()
      .then((url) => {
        setSpecificCE(url);
      })
      .catch((error) => {
        switch (error.code) {
          case "storage/object-not-found":
            break;
          case "storage/unauthorized":
            break;
          case "storage/canceled":
            break;
          case "storage/unknown":
            break;
        }
      });
    // var fileRef = pathReference.child("6cfef1e9-4c89-4bfd-94b1-9f9b4a78d580");
    // var gsReference = storage.refFromURL(
    //   "gs://cetracker-2de23.appspot.com/userImages/6aKT90OHXaekvltHIvPLOxlVSTv1/6cfef1e9-4c89-4bfd-94b1-9f9b4a78d580.jpeg"
    // );

    //for an array the word in will mean ceindex is the number.  for of it will iterate through.
    // for(const ceIndex in ceData) {
    //   console.log("ceIndex:", ceIndex);
    //   if(ceData[ceIndex].name == "name of ce your are searching for") {
    //   //Do something with the index here.
    //   }
    //   }

    //for objects
    // for(const ceIndex of ceData) {
    //   console.log("ceIndex:", ceIndex);
    //   if(ceData[ceIndex].name == "name of ce your are searching for") {
    //   //Do something with the index here.
    //   }
    //   }

    //     var ceID = "some id"
    // ceData[ceID]

    const db = firebase.firestore();
    const uid = user.uid;
    const file = ["668eedd0-24b1-4901-8178-88f13f68f789"];

    // db.collection("users")
    //   .doc(uid)
    //   .collection("CEs")
    //   .doc("CEData")
    //   .get()
    //   .then((res) => {
    //     if (res) {
    //       const ceInformation = res.data();
    //       setCE(ceInformation);
    //     } else {
    //       console.log("could not retrieve response");
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("error getting the document");
    //   });
  };

  const checkState = () => {
    console.log(specificCE);
  };

  const sendEmail = () => {
    const attachPic = firebase.functions().httpsCallable("singleExport");

    attachPic(specificCE)
      .then((result) => {
        console.log("attachPic's then part", result);
      })
      .catch((error) => {
        console.log("you have this error", error);
      });
  };

  useEffect(() => {
    if (CE) {
      console.log(CE);
    }
  }, [CE]);

  return (
    <div>
      <p>This is the picture your trying to email.</p>
      <button onClick={exportHandler}>export</button>
      <button onClick={checkState}>check state</button>
      <button onClick={sendEmail}>Send Email</button>
      <img src={specificCE} width="500" height="500"></img>
    </div>
  );
};

export default Testing;
