import React from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";
import { Link } from "react-router-dom";

export const WhatWeOffer = () => {
  return (
    <div>
      <Navbar1 />
      <div className="container pb-5">
        <p style={fontStyle}>
          <Link to="/" style={linkFormat}>
            Back
          </Link>
        </p>
        <div className="row justify-content-center py-4">
          <h4>What we offer</h4>
        </div>
        <p style={fontStyle}>
          We offer a Continuing Education (CE) tracker to help professionals
          manage the CE process, beginning at taking the class and going through
          submitting licensure renewal.
        </p>
        <p style={fontStyle}>
          CEsimply makes organizing and storing your CE documents seamless, even
          if you have multiple licenses and certifications to manage. You will
          have the opportunity to apply your CE documents to multiple licenses
          or certifications.
        </p>
        <p style={fontStyle}>
          All due dates are kept face up and in one location to easily know what
          deadlines are coming up. Gone are the days you put your CEs in a
          drawer for years until your license is renewed. Scan a picture of your
          documents and have it stored safely in the app for as long as you
          need-for free! Find CEsimply in the App Store or Google Play Store.
        </p>
      </div>
      <div className="container-fluid" style={footerFormat}>
        <Footer />
      </div>
    </div>
  );
};

const fontStyle = {
  fontSize: "0.8em",
};

const linkFormat = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "500",
};

const footerFormat = {
  position: "fixed",
  left: "0px",
  bottom: "0px",
  paddingLeft: "0px",
  paddingRight: "0px",
};

export default WhatWeOffer;
