import React from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import lifeSavingCertified from "../tools/pictures/ceProviders/lifeSavingCertified.png";

export const CEProvider = () => {
  return (
    <div>
        <Container style={{ paddingBottom: "2em" }}>
          <Row>
            <Col>
            <h5 style={{ textAlign: "center", paddingBottom: "1em" }}>Find continuing education near you!</h5>
            </Col>
          </Row>
          <Row style={eachProviderRow}>
            <Col sm={7} lg={6} style={firstColumnFormat}>
              <Row>
                <Col lg={4} style={{ padding: "0 0 0 0" }}><img src={lifeSavingCertified} alt="Life Saving Certified Logo" style={{ width: "100%", height: "auto" }}></img></Col>
              </Row>
              <Row>
                <h6>Life Saving Certified</h6>
              </Row>
              <Row>
                <p style={descriptionFont}>Upland, Riverside, Palm Desert, Visalia<br></br>213-435-4212<br></br>info@lifesavingcertified.com</p>
              </Row>
              <Row>
                <a href="https://lifesavingcertified.com" style={linkStyle}>lifesavingcertified.com</a>
              </Row>
            </Col>
            <Col sm={12} lg={6} style={secondColumnFormat}>
              <ul style={bulletedPerkListFormat}>
                <li>Official American Heart Association Training facility</li>
                <li>Offers Heartsaver CPR classes, BLS, ACLS, PALS and NRP</li>
                <li>BLS and ACLS renewals are quick and easy</li>
                <li>eCards issued right then and there</li>
                <li>Travels to you!  Big or small classes</li>
              </ul>
            </Col>
          </Row>
        </Container>
    </div>
  )
};

const eachProviderRow = {
  backgroundColor: "rgba(237, 244, 247, 1)",
  borderRadius: "5px",
  paddingLeft: "2em",
  paddingRight: "2em",
};

const firstColumnFormat = {
  paddingBottom: "1em",
}

const secondColumnFormat = {
  padding: "0 0 0 0",
};

const bulletedPerkListFormat = {
  fontSize: "0.7em",
  paddingTop: "0",
  paddingRight: "0",
  paddingBottom: "0",
  paddingLeft: "1em",
};

const descriptionFont = {
  fontSize: "0.7em",
  marginBottom: "0px",
};

const linkStyle = {
  fontSize: "0.7em",
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "700",
};

export default CEProvider;