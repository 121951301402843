export const LOAD_LICENSE = "LOAD_LICENSE";
export const ADD_LICENSE = "ADD_LICENSE";
export const DELETE_LICENSE = "DELETE_LICENSE";
export const UPDATE_LICENSE = "UPDATE_LICENSE";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOAD_CE = "LOAD_CE";
export const ADD_CE = "ADD_CE";
export const MODIFY_CE = "MODIFY_CE";
export const DELETE_CE = "DELTE_CE";
