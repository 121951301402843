import React from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import addLicenseOrCertHero from "../tools/pictures/addLicenseOrCertHero.jpg";
import addLicense1_home_phone_denotation from "../tools/pictures/addLicense1_home_phone_denotation.png";
import addLicense2_selectCredential_phone from "../tools/pictures/addLicense2_selectCredential_phone.png";
import addLicense3_addDetails_phone from "../tools/pictures/addLicense3_addDetails_phone.png";
import addLicense4_addCEs_phone_denotation from "../tools/pictures/addLicense4_addCEs_phone_denotation.png";
import addLicense5_addAdditionalRequirements_phone_denotation from "../tools/pictures/addLicense5_addAdditionalRequirements_phone_denotation.png";
import addLicense6_licenseCard_phone from "../tools/pictures/addLicense6_licenseCard_phone.png";

export const AddLicenseOrCertification = () => {
  const howToStructuredData = {
    "@context": "https://schema.org",
    "@type": "HowTo",
    name: "How to add a license in continuing education tracking app",
    step: [
      {
        "@type": "HowToStep",
        name: "Adding a Credential",
        text: "Click on '+Add Credential' button",
        image:
          "https://cesimply.com/blogPictures/howTo/AddLicenseOrCertification/addLicense1_home_phone_denotation.png",
      },
      {
        "@type": "HowToStep",
        name: "Selecting which Credential",
        text: "Select if this is a license or certification. Please read first paragraph above to see the difference.",
        image:
          "https://cesimply.com/blogPictures/howTo/AddLicenseOrCertification/addLicense2_selectCredential_phone.png",
      },
      {
        "@type": "HowToStep",
        name: "Add details",
        text: "Add details of the license or certification",
        image:
          "https://cesimply.com/blogPictures/howTo/AddLicenseOrCertification/addLicense3_addDetails_phone.png",
      },
      {
        "@type": "HowToStep",
        name: "Add continuing education hours",
        text: "Add how many continuing education (CE) hours are needed for this license or certification",
        image:
          "https://cesimply.com/blogPictures/howTo/AddLicenseOrCertification/addLicense4_addCEs_phone_denotation.png",
      },
      {
        "@type": "HowToStep",
        name: "Add addtional requirements",
        text: "If you have any additional requirements other than the required CE hours, e.g. 2 additional hours of infection control, you can add that in by clicking “Add Additional Requirement”. If your license or certification doesn’t require additional requirements just leave it blank. To finish, click “Add New License” and that’s it!",
        image:
          "https://cesimply.com/blogPictures/howTo/AddLicenseOrCertification/addLicense5_addAdditionalRequirements_phone_denotation.png",
      },
      {
        "@type": "HowToStep",
        name: "Final screen",
        text: "You should now see a new license or certification card on the credentails tab in the app.",
        image:
          "https://cesimply.com/blogPictures/howTo/AddLicenseOrCertification/addLicense6_licenseCard_phone.png",
      },
    ],
  };

  const JSONschema = JSON.stringify(howToStructuredData);

  return (
    <div>
      <Helmet>
        <title>
          Adding a license - Continuing Education Tracker | CEsimply
        </title>
        <meta
          name="description"
          content="How to add a license in a continuing education tracker app to begin tracking how many continuing education hours (CEs) have been taken."
        />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>

      <Navbar1 />
      <div className="pb-5">
        <Container>
          <p>
            <Link to="/Blog" style={backFormat}>
              Back
            </Link>
          </p>
          <Row>
            <Col style={{ padding: "0 0 0 0" }}>
              <img
                src={addLicenseOrCertHero}
                alt="desk with papers"
                style={{
                  width: "100%",
                  height: "auto",
                }}
              ></img>
            </Col>
          </Row>
          <Row>
            <p style={titleStyle}>How to add a License or Certification</p>
            <p style={paragraphStyle}>
              First of all, thanks for downloading the app! The first few times
              doing anything can be confusing so we’re here to help. The first
              thing you want to do is to add a license or a certification that
              you want to begin tracking CEs for. The difference between the two
              is a license is usually something that you have earned through the
              state and an active roster is kept and managed by the state, while
              a certification is usually something you earn through a group, a
              brand, or an association e.g. The American Heart Association.
              Certifications can range from something simple such as Basic Life
              Support (BLS) all the way up to a specializations like Oncology
              Certified Nurse (OCN) by the Oncology Nursing Certification
              Corporation. Some certifications require continuing education to
              maintain and some don’t. You’re welcome to use the app to track
              any of them, even if it’s just to keep track of when it expires.
              Remember, we’ll notify you 3 months out if any of your licenses or
              certifications are expiring.
            </p>
            <p style={subheaderStyle1}>Steps</p>
          </Row>
          <Row>
            <p style={subheaderStyle2}>
              To add a license or certification, ensure you are on the
              credentials tab.
            </p>
          </Row>
          <Row>
            <img
              src={addLicense1_home_phone_denotation}
              alt="home screen"
              style={{
                width: "25%",
                height: "auto",
                margin: "auto",
              }}
            ></img>
          </Row>
          <Row className="justify-content-center">
            <p style={subheaderStyle2}>Click on “+Add Credential” button.</p>
          </Row>
          <Row>
            <img
              src={addLicense2_selectCredential_phone}
              alt="home screen"
              style={{
                width: "25%",
                height: "auto",
                margin: "auto",
                paddingTop: "4em",
              }}
            ></img>
          </Row>
          <Row className="justify-content-center">
            <p style={subheaderStyle2}>
              Select if this is a license or certification. Please read first
              paragraph above to see the difference.
            </p>
          </Row>
          <Row>
            <img
              src={addLicense3_addDetails_phone}
              alt="home screen"
              style={{
                width: "25%",
                height: "auto",
                margin: "auto",
                paddingTop: "4em",
              }}
            ></img>
          </Row>
          <Row className="justify-content-center">
            <p style={subheaderStyle2}>
              Add details of the license or certification.
            </p>
          </Row>
          <Row>
            <img
              src={addLicense4_addCEs_phone_denotation}
              alt="home screen"
              style={{
                width: "25%",
                height: "auto",
                margin: "auto",
                paddingTop: "4em",
              }}
            ></img>
          </Row>
          <Row className="justify-content-center">
            <p style={subheaderStyle2}>
              Add how many continuing education (CE) hours are needed for this
              license or certification.
            </p>
          </Row>
          <Row>
            <img
              src={addLicense5_addAdditionalRequirements_phone_denotation}
              alt="home screen"
              style={{
                width: "25%",
                height: "auto",
                margin: "auto",
                paddingTop: "4em",
              }}
            ></img>
          </Row>
          <Row className="justify-content-center">
            <p style={subheaderStyle2}>
              If you have any additional requirements other than the required CE
              hours, e.g. 2 additional hours of infection control, you can add
              that in by clicking “Add Additional Requirement”. If your license
              or certification doesn’t require additional requirements just
              leave it blank. To finish, click “Add New License” and that’s it!{" "}
            </p>
          </Row>
          <Row className="justify-content-center">
            <p style={{ fontSize: "0.8em", paddingTop: "4em" }}>
              You should now see a new license or certification card on the
              credentails tab in the app.
            </p>
            <img
              src={addLicense6_licenseCard_phone}
              alt="home screen"
              style={{
                width: "25%",
                height: "auto",
                margin: "auto",
              }}
            ></img>
            <p style={{ fontSize: "0.7em", paddingTop: "1em" }}>
              Now you just need to start adding some CEs. For more information
              on how to do that, you can check out this article on how to add
              CEs to your license.
            </p>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

const backFormat = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "500",
};

const linkFormat = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "700",
};

const titleStyle = {
  paddingTop: "2em",
  fontWeight: "700",
};

const subheaderStyle1 = {
  fontSize: "0.9em",
};

const subheaderStyle2 = {
  fontSize: "0.8em",
  paddingTop: "1em",
};

const paragraphStyle = {
  fontSize: "0.7em",
};

export default AddLicenseOrCertification;
