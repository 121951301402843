import React, { useContext, useReducer, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import LicenseContext from "./licenseContext";
import licenseReducer from "./licenseReducer";
import AuthContext from "../auth/authContext";
import firebase from "firebase/app";
import "firebase/firestore";
import {
  LOAD_LICENSE,
  ADD_LICENSE,
  DELETE_LICENSE,
  UPDATE_LICENSE,
} from "../types";

const LicenseState = (props) => {
  const authContext = useContext(AuthContext);

  const { user } = authContext;

  const initialState = {
    license: {},
    licenseId: "",
    isEmpty: true,
    redirect: null,
  };

  const [state, dispatch] = useReducer(licenseReducer, initialState);

  //load licenses from firebase
  const getLicenseData = () => {
    const uid = user.uid;
    const db = firebase.firestore();
    db.collection("users")
      .doc(uid)
      .collection("licenses")
      .doc("licenseData")
      .get()
      .then((response) => {
        const data = response.data();

        if (data) {
          dispatch({
            type: LOAD_LICENSE,
            payload: data,
          });
        } else {
          console.log("could not retrieve data");
        }
      })
      .catch((error) => {
        console.log("error getting document", error);
      });
  };

  useEffect(() => {
    if (user && Object.keys(user).length) {
      getLicenseData();
    }
  }, [user]);

  useEffect(() => {
    if (state.license) {
      console.log(state.license);
    }
  }, [state.license]);

  //Add license
  const addNewLicense = (licenseData) => {
    const licenseId = uuidv4();
    licenseData.id = licenseId;
    const licenseObj = {
      [licenseId]: licenseData,
    };
    const uid = user.uid;
    const db = firebase.firestore();
    db.collection("users")
      .doc(uid)
      .collection("licenses")
      .doc("licenseData")
      .set(licenseObj, { merge: true })
      .then(function () {
        console.log("document written successfully");
        dispatch({
          type: ADD_LICENSE,
        });
        getLicenseData();
      })
      .catch(function (error) {
        console.error("error writing document", error);
      });
  };

  //Delete license

  //Update license

  return (
    <LicenseContext.Provider
      value={{
        license: state.license,
        redirect: state.redirect,
        getLicenseData,
        addNewLicense,
      }}
    >
      {props.children}
    </LicenseContext.Provider>
  );
};

export default LicenseState;
