import React from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const BestWayToKeepTrackOfCEs = () => {
  const articleStructuredData = {
    "@context": "https://schema.org",
    "@type": "Artcile",
    headline:
      "What is the best way to keep track of my continuing education (CEs)?",
    image:
      "https://cesimply.com/pictures/blogPictures/bestWayToKeepTrackOfCEHero.jpg",
    datePublished: "2022-03-16T08:00:11+00:00",
    dateModified: "2022-04-12T09:20:00+08:00",
    author: {
      "@type": "Person",
      name: "Michael Hwang",
    },
  };

  const JSONschema = JSON.stringify(articleStructuredData);

  return (
    <div>
      <Helmet>
        <title>Best way to track CEs - Continuing Education | CEsimply</title>
        <meta
          name="description"
          content="The best way to store continuing education is with an electronic education tracking app"
        />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>
      <Navbar1 />
      <div className="pb-5">
        <Container>
          <p>
            <Link to="/Blog" style={backFormat}>
              Back
            </Link>
          </p>
          <Row>
            <Col style={{ padding: "0 0 0 0" }}>
              <img
                src={articleStructuredData.image}
                alt="desk with papers"
                style={{
                  width: "90%",
                  height: "auto",
                }}
              ></img>
            </Col>
          </Row>
          <Row>
            <p style={titleStyle}>{articleStructuredData.headline}</p>
            <p style={paragraphStyle}>Published: March 16th, 2022</p>
            <p style={paragraphStyle}>
              The best way to keep track of your CEs is with a continuing
              education tracker (CE tracker), and not in a drawer somewhere.
              Why? Because there is some inherent risk to keeping a physical
              object in a drawer for safe keeping. There’s a chance it could get
              damaged, lost or forgotten.
            </p>
            <p style={paragraphStyle}>
              Plus if you’re like me and kinda unorganized, I never know if I
              really did put ALL my CEs in that drawer. If a CE got forgotten on
              a desk or binder somewhere I would never know. That’s why people
              started keeping their valuables in safes, but to do that with some
              of your CEs seems kinda overkill. But it does need to be kept
              safer than just your normal piece of paper. So I came to the
              realization that the best way to keep track of something like this
              is to do it electronically.
            </p>
            <p style={paragraphStyle}>
              In order for it to be stored electronically, these items were
              necessary:
              <ul>
                <li>CEs stored long term</li>
                <li>Easy to access</li>
                <li>Total the number of CEs taken for a given license</li>
                <li>Be able to keep a copy of the certificate</li>
              </ul>
              The last bullet point is very important because although it's
              possible to just keep track of CE details in a spreadsheet,
              keeping a copy of the actual certificate means you can print
              another copy of the certificate if something happens to the
              physical copy. Otherwise, what’s the point of doing it
              electronically if it still needs to be stored in a drawer?
            </p>
            <p style={paragraphStyle}>
              These reasons are some of the reasons why we created the CEsimply
              app. We feel it’s the best place for you to store your CEs. It’s
              free and you can store your CEs long term. That’s also very
              helpful if you have the unfortunate experience of having to be
              audited years later. If you want to take it a step further the app
              also offers the ability to turn in all your CEs for you when it
              comes time to renew. You may never have to handle your CE process
              anymore, once in the app it can do everything for you from
              storing, tracking to submitting. For more information on that
              process please visit{" "}
              <Link to="/Renew" style={linkFormat}>
                here
              </Link>
              .
            </p>
            <p>Click below to download the app today!</p>
          </Row>
          <Row>
            <div
              className="row justify-content-center py-5"
              style={{ margin: "auto" }}
            >
              <a
                href="https://apps.apple.com/us/app/cesimply/id1582382064?itsct=apps_box_badge&amp;itscg=30200"
                style={appleButton}
              >
                <img
                  src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1630281600&h=8bd2c7e704bd2fe62db5aa45a0be6168"
                  alt="Download on the App Store"
                  style={appleImgStyle}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.cetracker&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                style={playButton}
              >
                <img
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  style={playImgStyle}
                />
              </a>
            </div>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

const backFormat = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "500",
};

const linkFormat = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "700",
};

const titleStyle = {
  paddingTop: "2em",
  fontWeight: "700",
};

const paragraphStyle = {
  fontSize: "0.8em",
};

const appleButton = {
  display: "inline-block",
  overflow: "hidden",
  borderRadius: "13px",
};

const appleImgStyle = {
  borderRadius: "13px",
  width: "250px",
  height: "auto",
  padding: "20px",
};

const playButton = {
  display: "inline-block",
  overflow: "hidden",
  borderRadius: "13px",
};

const playImgStyle = {
  borderRadius: "13px",
  width: "243px",
  height: "110px",
  paddingTop: "5px",
};

export default BestWayToKeepTrackOfCEs;
