import React from "react";
import { Link } from "react-router-dom";

export const PricingFAQs = () => {
  return (
    <div className="container-fluid" style={{ marginBottom: "1rem" }}>
      <div className="row justify-content-center py-4">
        <h4 className="text-center">Frequently Asked Questions:</h4>
      </div>

      <div className="row justify-content-center px-3">
        <div className="col-md-6">
          <p
            className="text-wrap"
            style={{ fontSize: "0.8em", fontWeight: "700" }}
          >
            What states and licenses can you submit for?
          </p>
        </div>
        <div className="col-md-6" style={answerFontsize}>
          <p>
            Please visit our list{" "}
            <Link to="/SubmissionList" style={linkFormat}>
              here
            </Link>
            .
          </p>
        </div>
      </div>

      <div className="row justify-content-center px-3">
        <div className="col-md-6">
          <p
            className="text-wrap"
            style={{ fontSize: "0.8em", fontWeight: "700" }}
          >
            What if I’d like you to submit my renewal but my license/state isn’t
            on the list?
          </p>
        </div>
        <div className="col-md-6" style={answerFontsize}>
          <p>
            Just because we don’t submit for your license/state yet, doesn’t
            mean that we aren’t working towards doing so. If you’d be interested
            in helping us speed up this process for your license or state,
            please contact us{" "}
            <Link to="/Contact" style={linkFormat}>
              here
            </Link>
            . We may even pay your next renewal fee!
          </p>
        </div>
      </div>

      <div className="row justify-content-center px-3">
        <div className="col-md-6">
          <p
            className="text-wrap"
            style={{ fontSize: "0.8em", fontWeight: "700" }}
          >
            Can I cancel?
          </p>
        </div>
        <div className="col-md-6" style={answerFontsize}>
          <p>
            Yes, at any time. We will also give you the opportunity to export
            all saved files prior to cancellation.
          </p>
        </div>
      </div>

      <div className="row justify-content-center px-3">
        <div className="col-md-6">
          <p
            className="text-wrap"
            style={{ fontSize: "0.8em", fontWeight: "700" }}
          >
            Are there ever any discounts?
          </p>
        </div>
        <div className="col-md-6" style={answerFontsize}>
          <p>
            Yes! Contact us via our contact us page to be included when we do promotions.  But even without discounts, you will NOT find prices cheaper than ours!
          </p>
        </div>
      </div>
    </div>
  );
};

const answerFontsize = {
  fontSize: "0.8em",
};

const linkFormat = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "900",
};

export default PricingFAQs;
