import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import Features from "./components/pages/Features";
import FAQs from "./components/pages/FAQs";
import Contact from "./components/pages/Contact";
import Pricing from "./components/pages/Pricing";
import Signup from "./components/pages/Signup";
import Login from "./components/pages/Login";
import Alert1 from "./components/layout/Alert";
import Survey from "./components/pages/Survey";
import SubmissionList from "./components/pages/SubmissionList";
import PrivateRoute from "./components/tools/PrivateRoute";
import Dashboard from "./components/pages/Dashboard";
import AddLicense from "./components/pages/AddLicense";
import AddCE from "./components/pages/AddCE";
import Privacy from "./components/pages/Privacy";
import Terms from "./components/pages/Terms";
import Testing from "./components/pages/Testing";
import Refund from "./components/pages/Refund";
import Disclaimer from "./components/pages/Disclaimer";
import About from "./components/pages/About";
import Renew from "./components/pages/Renew";
import WhatWeOffer from "./components/pages/WhatWeOffer";
import WhyUs from "./components/pages/WhyUs";
import Testimonials from "./components/pages/Testimonials";
import Location from "./components/pages/Location";
import QRCodeGenerator from "./components/pages/QrCodeGenerator";
import CEProvider from "./components/pages/CEProvider";
import Blog from "./components/pages/Blog";
import BestWayToKeepTrackOfCEs from "./components/blog/BestWayToKeepTrackOfCEs";
import AddLicenseOrCertification from "./components/blog/AddLicenseOrCertification";
import LicenseVsCertification from "./components/blog/LicenseVsCertification";
import AddCEToLicense from "./components/blog/AddCEToLicense";
import NursingBurnout from "./components/blog/NursingBurnout";
import BestFeaturesCETracker from "./components/blog/BestFeaturesCETracker";
import ChargeCard from "./components/pages/ChargeCard";
import PaymentSuccess from "./components/pages/PaymentSuccess";

import LicenseState from "./components/context/license/LicenseState";
import AuthState from "./components/context/auth/AuthState";
import AlertState from "./components/context/alert/AlertState";
import CeState from "./components/context/ce/CeState";

// import TagManager from 'react-gtm-module';
// const tagmanagerArgs = {
//   gtmId: 'AW-11248978233'
// }

// TagManager.initialize(tagManagerArgs);

import "./App.css";

function App() {
  return (
    <AuthState>
      <LicenseState>
        <CeState>
          <AlertState>
            <Router>
              <div className="app">
                <Alert1 />
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/Features" component={Features} />
                  <Route exact path="/Pricing" component={Pricing} />
                  <Route exact path="/FAQs" component={FAQs} />
                  <Route exact path="/Contact" component={Contact} />
                  <Route exact path="/Signup" component={Signup} />
                  <Route exact path="/Login" component={Login} />
                  <Route exact path="/Survey/:uid" component={Survey} />
                  <Route
                    exact
                    path="/SubmissionList"
                    component={SubmissionList}
                  />
                  <Route exact path="/Privacy" component={Privacy} />
                  <Route exact path="/Terms" component={Terms} />
                  <Route exact path="/Refund" component={Refund} />
                  <Route exact path="/Disclaimer" component={Disclaimer} />
                  <Route exact path="/About" component={About} />
                  <Route exact path="/Renew" component={Renew} />
                  <Route exact path="/WhatWeoffer" component={WhatWeOffer} />
                  <Route exact path="/WhyUs" component={WhyUs} />
                  <Route exact path="/Testimonials" component={Testimonials} />
                  <Route exact path="/Location" component={Location} />
                  <Route
                    exact
                    path="/QRCodeGenerator"
                    component={QRCodeGenerator}
                  />
                  <Route exact path="/CEProvider" component={CEProvider} />
                  <Route exact path="/Blog" component={Blog} />
                  <Route
                    exact
                    path="/BestWayToKeepTrackOfCEs"
                    component={BestWayToKeepTrackOfCEs}
                  />
                  <Route
                    exact
                    path="/AddLicenseOrCertification"
                    component={AddLicenseOrCertification}
                  />
                  <Route
                    exact
                    path="/LicenseVsCertification"
                    component={LicenseVsCertification}
                  />
                  <Route
                    exact
                    path="/AddCEToLicense"
                    component={AddCEToLicense}
                  />
                  <Route
                    exact
                    path="/NursingBurnout"
                    component={NursingBurnout}
                  />
                  <Route
                    exact
                    path="/BestFeaturesCETracker"
                    component={BestFeaturesCETracker}
                  />
                  <Route exact path="/Testing" component={Testing} />
                  <Route exact path="/ChargeCard" component={ChargeCard} />
                  <Route exact path="/PaymentSuccess" component={PaymentSuccess} />
                  <PrivateRoute exact path="/Dashboard" component={Dashboard} />
                  <PrivateRoute
                    exact
                    path="/AddLicense"
                    component={AddLicense}
                  />
                  <PrivateRoute exact path="/AddCE" component={AddCE} />
                </Switch>
              </div>
            </Router>
          </AlertState>
        </CeState>
      </LicenseState>
    </AuthState>
  );
}

export default App;
