import React from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const FAQs = () => {
  const FAQStructuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What sites/licenses can you submit renewals for?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "<p>Please visit our list <a href=https://cesimply.com/SubmissionList>here.</a></p>",
        },
      },
      {
        "@type": "Question",
        name: "What if I’d like you to submit my renewal but my license/state isn’t on the list?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "<p>Just because we don’t submit your license/state yet, doesn’t mean that we aren’t working towards doing so. If you’d be interested in helping us speed up this process for your license or state, please contact us <a href=https://cesimply.com/Contact>here</a>. We might even pay your next renewal fee!</p>",
        },
      },
      {
        "@type": "Question",
        name: "Can I cancel?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "<p>Yes, at any time. We will also give you the opportunity to export all saved files prior to cancellation. If the cancellation is within 30 days of the purchase date, you may be eligible for a refund. For details please see <a href=https://cesimply.com/Refund>here</a>.</p>",
        },
      },
      {
        "@type": "Question",
        name: "Is it secure?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "<p>Your security is a top priority for us. We will treat your login information as if it was our own (after all, we're nurses ourselves!). For RNs and LVNs in California, you will appoint CEsimply as an Authorized Representative on the BreEZe website to aid in this secure process. We will never share your login details with third parties.</p>",
        },
      },
      {
        "@type": "Question",
        name: "What is an authorized representative?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "<p>An Authorized Representative is someone you have appointed to manage your license settings (ie, update your address for the BRN) and renew your license for you.  We will help you set CEsimply as an authorized representative on your account if you are unsure of how to do so.</p>",
        },
      },
      {
        "@type": "Question",
        name: "Will you really renew my license?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "<p>YES! You’ll answer all of your license renewal questions through the CEsimply app and we will submit them along with your CEs to your licensing board. Since you’ve been gradually gathering the details for each of your CEs in our app, we can just submit them for you and you won’t have to type them out a second time when renewing! For a detailed explanation of the process, please click <a href=https://cesimply.com/Renew>here</a>.</p>",
        },
      },
      {
        "@type": "Question",
        name: "Does it cost extra to have you renew my license?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "<p>If you would like to utilize our license renewal feature, please select our Concierge Plan on signup, which will allow us to renew any and all of your licenses. If you have the storage plan but would like to just renew one license, we can also do a one time renewal for $29.99.  You will still be responsible for paying your license renewal fee.</p>",
        },
      },
      {
        "@type": "Question",
        name: "Do I have to share my licensing board log in information?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "<p>Yes, in order for us to submit your license renewal with your licensing board, you will have to share your log in information.  This is to ensure we submit your CEs to the correct account for renewal.  A name alone does not provide enough positive user identification.  We understand how sacred this information is to you (we are nurses ourselves!), and because of this, we do everything we can to keep your information secure. We will never share your login information with third parties.</p>",
        },
      },
      {
        "@type": "Question",
        name: "What is Stripe and why do you use it?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "<p>Stripe is our third party billing company that is well known in the tech world for online payment assistance. Your billing process will be handled entirely through them. We selected Stripe again as an additional layer of security for you.</p>",
        },
      },
    ],
  };

  const JSONschema = JSON.stringify(FAQStructuredData);

  return (
    <div>
      <Helmet>
        <title>Continuing education tracker app - FAQs | CEsimply</title>
        <meta
          name="description"
          content="Frequently Asked Questions for CEsimply continuing education tracking app."
        />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>

      <Navbar1 />
      <div className="container pb-5">
        <div className="row justify-content-center py-4">
          <h4>FAQ</h4>
        </div>
        <h6 style={question}>
          What sites/licenses can you submit renewals for?
        </h6>
        <p style={answer}>
          Please visit our list{" "}
          <Link to="/SubmissionList" style={linkFormat}>
            here
          </Link>
          .
        </p>
        <br></br>
        <h6 style={question}>
          What if I’d like you to submit my renewal but my license/state isn’t
          on the list?
        </h6>
        <p style={answer}>
          Just because we don’t submit your license/state yet, doesn’t mean that
          we aren’t working towards doing so. If you’d be interested in helping
          us speed up this process for your license or state, please contact us{" "}
          <Link to="/Contact" style={linkFormat}>
            here
          </Link>
          . We might even pay your next renewal fee!
        </p>
        <br></br>
        <h6 style={question}>Can I cancel?</h6>
        <p style={answer}>
          Yes, at any time. We will also give you the opportunity to export all
          saved files prior to cancellation. If the cancellation is within 30
          days of the purchase date, you may be eligible for a refund. For
          details please see{" "}
          <Link to="/Refund" style={linkFormat}>
            here.
          </Link>
        </p>
        <br></br>
        <h6 style={question}>Is it secure?</h6>
        <p style={answer}>
          Your security is a top priority for us. We will treat your login
          information as if it was our own (after all, we’re nurses ourselves!).
          For RNs and LVNs in California, you will appoint CEsimply as an
          Authorized Representative on the BreEZe website to aid in this secure
          process. We will never share your login details with third parties.
        </p>
        <br></br>
        <h6 style={question}>What is an authorized representative?</h6>
        <p style={answer}>
          An Authorized Representative is someone you have appointed to manage
          your license settings (ie, update your address for the BRN) and renew
          your license for you. We will help you set CEsimply as an authorized
          representative on your account if you are unsure of how to do so.
        </p>
        <br></br>
        <h6 style={question}>Will you really renew my license?</h6>
        <p style={answer}>
          YES! You’ll answer all of your license renewal questions through the
          CEsimply app and we will submit them along with your CEs to your
          licensing board. Since you’ve been gradually gathering the details for
          each of your CEs in our app, we can just submit them for you and you
          won’t have to type them out a second time when renewing! For a
          detailed explanation of the process, please click{" "}
          <Link to="/Renew" style={linkFormat}>
            here
          </Link>
          .
        </p>
        <br></br>
        <h6 style={question}>
          Does it cost extra to have you renew my license?
        </h6>
        <p style={answer}>
          If you would like to utilize our license renewal feature, please select 
          our $25/year Concierge Plan on signup, which will allow us to renew any and all 
          of your licenses. If you have the storage plan but would like to just renew 
          one license, we can also do a one time renewal for $29.99.  You will still 
          be responsible for paying your license renewal fee.
        </p>
        <br></br>
        <h6 style={question}>
          Do I have to share my licensing board log in information?
        </h6>
        <p style={answer}>
          Yes, in order for us to submit your license renewal with your
          licensing board, you will have to share your log in information. This
          is to ensure we submit your CEs to the correct account for renewal. A
          name alone does not provide enough positive user identification. We
          understand how sacred this information is to you (we are nurses
          ourselves!), and because of this, we do everything we can to keep your
          information secure. We will never share your login information with
          third parties.
        </p>
        <br></br>
        <h6 style={question}>What is Stripe and why do you use it?</h6>
        <p style={answer}>
          Stripe is our third party billing company that is well known in the
          tech world for online payment assistance. Your billing process will be
          handled entirely through them. We selected Stripe again as an
          additional layer of security for you.
        </p>
      </div>
      <Footer />
    </div>
  );
};

const question = {
  color: "rgb(35, 81, 133)",
  fontSize: "0.9em",
};

const answer = {
  fontSize: "0.8em",
};

const linkFormat = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "900",
};

export default FAQs;
