import React from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";
import Plans from "../layout/Plans";
import PricingFAQs from "../layout/PricingFAQs";

export const Pricing = () => {
  return (
    <div>
      <Navbar1 />
      <Plans />
      <PricingFAQs />
      <Footer />
    </div>
  );
};

export default Pricing;
