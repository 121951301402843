import userEvent from "@testing-library/user-event";
import React, { useState, useContext } from "react";
import { Redirect } from "react-router-dom";

import LicenseContext from "../context/license/licenseContext";

//set alerts for when the fields are filled out improperly

export const AddLicense = () => {
  const licenseContext = useContext(LicenseContext);

  const { addNewLicense, redirect } = licenseContext;

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const [licenseData, setLicenseData] = useState({
    licenseType: "",
    otherLicenseType: "",
    licenseState: "",
    licenseNum: "",
    licenseExpiration: "",
    licensePhoto: "",
    licenseThumbnail: "",
    totalCEHours: "",
    requirements: [],
  });

  // const [redirect, setRedirect] = useState(null);

  const {
    licenseType,
    licenseState,
    licenseNum,
    licenseExpiration,
    totalCEHours,
  } = licenseData;

  const onChange = (e) =>
    setLicenseData({ ...licenseData, [e.target.name]: e.target.value });

  const addLicense = () => {
    addNewLicense(licenseData);
  };

  // useEffect(() => {
  //   console.log(`redirect in addlicense page worked`);
  //   if (redirect) {
  //     return <Redirect to={redirect} />;
  //   }
  // }, [redirect]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div style={{ fontSize: "0.7em" }}>
      <div className="container bg-light">
        <div className="col-md-8">
          <h5 className="mb-3">Add a License</h5>
          <hr className="mb-4"></hr>
          <form>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label for="licenseType">License Type</label>
                <select
                  type="text"
                  className="custom-select d-block w-100"
                  id="licenseType"
                  name="licenseType"
                  value={licenseType}
                  onChange={onChange}
                  style={inputStyle}
                >
                  <option>Select License Type</option>
                  <option>Registered Nurse (RN)</option>
                  <option>Licensed Vocational Nurse (LVN)</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div classname="col-md-6 mb-3">
                <label for="state">State</label>
                <select
                  className="custom-select d-block w-100"
                  id="licenseState"
                  name="licenseState"
                  value={licenseState}
                  onChange={onChange}
                  style={inputStyle}
                >
                  <option value="">Choose...</option>
                  <option>California</option>
                  <option>Kansas</option>
                </select>
              </div>
              <div className="col-md-6 mb-3">
                <label for="licenseNum">License Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="licenseNum"
                  name="licenseNum"
                  value={licenseNum}
                  onChange={onChange}
                  style={inputStyle}
                  placeholder="e.g. 4134234"
                  required
                ></input>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label for="licenseExpiration">License Expiration</label>
                <input
                  type="date"
                  class="form-control"
                  id="licenseExpiration"
                  value={licenseExpiration}
                  style={inputStyle}
                  name="licenseExpiration"
                  onChange={onChange}
                ></input>
              </div>
            </div>
            <div className="row">
              <h6>CE Information</h6>
            </div>
            <hr className="mb-4"></hr>
            <div className="row">
              <div className="mb-3 col-md-4">
                <label for="totalCEHours">Total hours</label>
                <input
                  type="text"
                  class="form-control"
                  id="totalCEHours"
                  placeholder="e.g. 30"
                  name="totalCEHours"
                  style={inputStyle}
                  value={totalCEHours}
                  onChange={onChange}
                ></input>
              </div>
              <button type="button" className="col-md-6 button-outline">
                <i class="fas fa-folder-plus pr-2"></i>Add Requirement
              </button>
            </div>
            <div className="row">
              <p>Additional Requirements</p>
              <p>
                Some states have special requirements for license renewal. Click
                Add Requirement to add some!
              </p>
            </div>
            <button
              type="button"
              className="btn btn-primary btn-lg"
              style={buttonColor}
              onClick={addLicense}
            >
              Add New License
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

const buttonColor = {
  color: "#fff",
  backgroundColor: "rgb(35, 81, 133)",
  borderColor: "rgb(35, 81, 133)",
  fontSize: "1em",
};

const inputStyle = {
  fontSize: "0.8em",
};

const addRequirementButton = {
  color: "rgb(35, 81, 133)",
  borderColor: "rgb(35, 81, 133)",
  backgroundColor: "rgb(255, 255, 255, 0.3)",
  marginTop: "1.8rem",
  height: "2.5em",
  borderRadius: "10px",
};

export default AddLicense;
