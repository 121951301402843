import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../context/auth/authContext";
import AlertContext from "../context/alert/alertContext";
import { Link } from "react-router-dom";
import logo2 from "../tools/pictures/logo2.png";

//to do:

export const Login = (props) => {
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);

  const { login, error, isAuthenticated } = authContext;
  const { setAlert } = alertContext;

  useEffect(() => {
    if (isAuthenticated) {
      props.history.push("/Dashboard");
    }

    if (error && error.message) {
      setAlert(`${error.message}`, "danger");
    }
  }, [error, isAuthenticated]);

  const [user1, setUser] = useState({
    email: "",
    password: "",
  });

  const { email, password } = user1;

  const onChange = (e) =>
    setUser({ ...user1, [e.target.name]: e.target.value });

  const onSubmit = () => {
    login(email, password);
  };

  const handleEnter = (e) => {
    if (e.key == "Enter") {
      login(email, password);
    }
  };

  return (
    <div style={{ backgroundColor: "rgb(35, 81, 133)" }}>
      <div
        className="container"
        style={{
          backgroundColor: "rgb(35, 81, 133)",
          paddingBottom: "12rem",
        }}
      >
        <div className="row justify-content-center">
          <img src={logo2} alt="Logo" className="pt-lg-1"></img>
        </div>
        <div className="row">
          <div className="col" style={{ textAlign: "center" }}>
            <p style={{ color: "white" }}>Welcome back!</p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-8" style={loginForm}>
            <div className="row form-row">
              <div className="col">
                <input
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  style={formField}
                  required
                  value={email}
                  onChange={onChange}
                ></input>
              </div>
            </div>
            <div className="row form-row">
              <div className="col">
                <input
                  name="password"
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  style={formField}
                  required
                  value={password}
                  onChange={onChange}
                  onKeyDown={handleEnter}
                ></input>
              </div>
            </div>
            <div className="row form-row justify-content-center">
              <button
                type="submit"
                className="btn btn-sm"
                style={buttonColor}
                onClick={onSubmit}
              >
                Log in
              </button>
            </div>
            <div className="row form-row justify-content-center">
              <p style={{ fontSize: "0.6em" }}>
                Don't have an account? Sign up <Link to="/Signup">here</Link>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

const loginForm = {
  backgroundColor: "white",
  borderRadius: "15px",
};

const formField = {
  fontSize: "0.7em",
  backgroundColor: "rgba(248, 251, 252, 1)",
};

const buttonColor = {
  color: "white",
  backgroundColor: "rgb(35, 81, 133)",
  fontSize: "1em",
};
