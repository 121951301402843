import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
//Do I need this next line?  after tesitng the email comment it out
import "firebase/firestore";

import Privacy from "./Privacy";
import Terms from "../pages/Terms";

import AuthContext from "../context/auth/authContext";
import AlertContext from "../context/alert/alertContext";
import { getConfig } from "../../config.js";

import logo2 from "../tools/pictures/logo2.png";

//to do:
//Add links to privacy policy and terms under Signup button

export const Signup = (props) => {
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);

  const { register, error, isAuthenticated, user } = authContext;
  const { setAlert } = alertContext;

  const config = getConfig();

  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    password2: "",
  });

  const { firstName, lastName, email, password, password2 } = userInfo;

  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    try {
      if (isDone) {
        props.history.push("/Dashboard");
      }
    } catch (error) {
      console.log(error);
    }
  }, [isDone]);

  useEffect(() => {
    try {
      if (user) {
        const db = firebase.firestore();
        const uid = user.uid;

        db.collection("users")
          .doc(uid)
          .set(
            { email: email, firstName: firstName, lastName: lastName },
            { merge: true }
          )
          .then(() => {
            setIsDone(true);
          });
      }
    } catch (error) {
      console.log(error);
    }
  }, [user]);

  const onChange = (e) =>
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });

  const sendOnboardingEmail = async () => {
    try {
      console.log("sendOnboardingEmail");
      const data = {
        email: email,
        firstName: firstName,
      };

      const dataStr = JSON.stringify(data);

      var emailResponse = await fetch(
        `${config.functionsURI}/onboardingEmail`,
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          // body: JSON.stringify(data),
          body: `data=${dataStr}`,
        }
      );

      if (emailResponse.status !== 200)
        throw new Error("Something went wrong. Please try again");

      console.log("Successfully sent email!");
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  //code to grab email address via a query with uid and then write into state the email address.
  //This was when I created a button just to trigger the query and then call the firebase function to test, originally written on hero.js
  // const [userEmail, setUserEmail] = useState("");

  // const submitButton = () => {
  //   const uid = user.uid;
  //   const db = firebase.firestore();
  //   db.collection("users")
  //   .doc(uid)
  //   .get()
  //   .then((response) => {
  //     if (response) {
  //       const email = response.data().email;
  //       setUserEmail(email);
  //     } else {
  //       console.log("could not retrieve response or write to state");
  //     }
  //   })
  //   .catch((error) => {
  //     console.log("error sent by firebase about getting the document", error);
  //   });
  // };

  //originally state value would not show up when console logged.  so the firebase function was not receiving the value.
  //added useEffect to wait until state would change before sending the email
  // useEffect(() => {
  //   var sendEmail = firebase.functions().httpsCallable("onboardingEmail");

  //   if (userEmail) {
  //     sendEmail(userEmail)
  //     .then((result) => {
  //       console.log(JSON.stringify(result));
  //     })
  //     .catch((error) => {
  //       const stringifyError = JSON.stringify(error);
  //       console.log(`You have this error: ${stringifyError}`);
  //     })
  //   }
  // }, [userEmail]);

  const onSubmit = () => {
    // if (
    //   firstName === "" ||
    //   lastName === "" ||
    //   email === "" ||
    //   password === ""
    // ) {
    //   setAlert("Please Enter All Fields", "danger");
    // } else if (password.length < 6 || password2.length < 6) {
    //   setAlert("Password must be a minimum of 6 characters", "danger");
    // } else if (password !== password2) {
    //   setAlert("Passwords Do Not Match", "danger");
    // } else {
    //   register(email.trim(), password);
    // }
    sendOnboardingEmail();
  };

  const handleEnter = (e) => {
    if (e.key == "Enter") {
      onSubmit();
    }
  };

  return (
    <div style={{ backgroundColor: "rgb(35, 81, 133)" }}>
      <div
        className="container"
        style={{ backgroundColor: "rgb(35, 81, 133)", paddingBottom: "12rem" }}
      >
        <div className="row justify-content-center">
          <img src={logo2} alt="Logo" className="pt-lg-1 pb-1"></img>
        </div>
        <div className="row">
          <div className="col" style={{ textAlign: "center" }}></div>
        </div>
        <div className="row justify-content-center">
          <div className="col-8" style={signupForm}>
            <div className="row form-row">
              <div className="col">
                <input
                  name="firstName"
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  style={formField}
                  value={firstName}
                  onChange={onChange}
                ></input>
              </div>
              <div className="col">
                <input
                  name="lastName"
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  style={formField}
                  value={lastName}
                  onChange={onChange}
                ></input>
              </div>
            </div>
            <div className="row form-row">
              <div className="col">
                <input
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  style={formField}
                  required
                  value={email}
                  onChange={onChange}
                ></input>
              </div>
            </div>
            <div className="row form-row">
              <div className="col">
                <input
                  name="password"
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  style={formField}
                  required
                  value={password}
                  onChange={onChange}
                ></input>
              </div>
              <div className="col">
                <input
                  name="password2"
                  type="password"
                  className="form-control"
                  placeholder="Confirm Password"
                  style={formField}
                  required
                  value={password2}
                  onChange={onChange}
                  onKeyDown={handleEnter}
                ></input>
              </div>
            </div>
            <div className="row form-row justify-content-center">
              <button
                type="submit"
                className="btn btn-sm"
                style={buttonColor}
                onClick={onSubmit}
              >
                Sign Up
              </button>
            </div>
            <div className="row form-row justify-content-center">
              <p style={{ fontSize: "0.4em" }}>
                By clicking Create Account you agree to our{" "}
                <Link to="/Terms">Terms</Link> and{" "}
                <Link to="/Privacy">Privacy Policy</Link>.
              </p>
              <p style={{ fontSize: "0.5em" }}>
                Already have an account? Click <Link to="/Login">here</Link>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;

const signupForm = {
  backgroundColor: "white",
  borderRadius: "15px",
};

const formField = {
  fontSize: "0.7em",
  backgroundColor: "rgba(248, 251, 252, 1)",
};

const buttonColor = {
  color: "white",
  backgroundColor: "rgb(35, 81, 133)",
  fontSize: "1em",
};
