import React, { useContext } from "react";
import LicenseItem from "./LicenseItem";

import LicenseContext from "../context/license/licenseContext";

export const License = () => {
  const licenseContext = useContext(LicenseContext);

  const { license } = licenseContext;

  return (
    <div>
      {Object.entries(license).map(
        (licenseVariable) => (
          console.log(licenseVariable),
          (<LicenseItem key={license.id} license={licenseVariable} />)
        )
      )}
      ;
    </div>
  );
};

export default License;
