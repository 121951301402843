import React, { useContext, useState } from "react";

import CeContext from "../context/ce/ceContext";

export const AddCE = () => {
  const ceContext = useContext(CeContext);

  const { addNewCE } = ceContext;

  const [ceData, setCeData] = useState({
    name: "",
    hours: "",
    completionDate: "",
    providerNum: "",
    ceThumbnail: "",
    cePhoto: "",
  });

  const { name, hours, completionDate, providerNum } = ceData;

  const onChange = (e) =>
    setCeData({ ...ceData, [e.target.name]: e.target.value });

  const addCE = () => {
    addNewCE(ceData);
  };

  return (
    <div style={{ fontSize: "0.7em" }}>
      <div className="container bg-light">
        <div className="col-md-8">
          <h5 className="mb-3">Add a CE</h5>
          <hr className="mb-4"></hr>
          <form>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label for="name">Name of CE</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={name}
                  onChange={onChange}
                  style={inputStyle}
                  placeholder="e.g. Bioterrorism"
                ></input>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label for="providerNum">Provider Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="providerNum"
                  name="providerNum"
                  value={providerNum}
                  onChange={onChange}
                  style={inputStyle}
                  placeholder="4321PE1"
                ></input>
              </div>
              <div className="col-md-6 mb-3">
                <label for="hours">Hours</label>
                <input
                  type="text"
                  className="form-control"
                  id="hours"
                  name="hours"
                  value={hours}
                  onChange={onChange}
                  style={inputStyle}
                  placeholder="e.g. 3"
                ></input>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label for="completionDate">Date Completed</label>
                <input
                  type="text"
                  className="form-control"
                  id="completionDate"
                  name="completionDate"
                  value={completionDate}
                  onChange={onChange}
                  style={inputStyle}
                  placeholder="MM/DD/YYYY"
                ></input>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label for="cePhoto">CE Photo (optional)</label>
                <p>Clickable Thumbnail goes here</p>
              </div>
            </div>
            <button
              type="button"
              className="col-md-6 mb-5 button-outline"
              style={{ marginTop: "0rem" }}
              onClick={addCE}
            >
              Save
            </button>
          </form>
          <h5 className="mb-3">Link to Licenses/Certifications</h5>
          <hr className="mb-2"></hr>
          <button type="button" className="col-md-6 button-outline">
            Link CE
          </button>
        </div>
      </div>
    </div>
  );
};

const inputStyle = {
  fontSize: "0.8em",
};

export default AddCE;
