import React from 'react'
import Container from 'react-bootstrap/Container';

export const WhyChooseUs = () => {
  return (
    <div className="pb-5">
        <Container>
            <h4 style={titleFont}>Why choose us?</h4>
            <ul>
                <li>With our app, it's like having an assistant manage your CEUs!  No more hassle keeping track of it!</li>
                <li>Unlimited, lifelong storage</li>
                <li>Easy-to-use app design</li>
                <li>Got audited? Easily export all documents with a click</li>
                <li>Manage multiple licenses, certifications, and due dates all in one place.</li>
                <li>Need a continuing education certificate to count towards 2 different licenses or certifications?  No problem.</li>
                <li>Let CEsimply count your CE hours for you electronically so you don't waste time counting and re-counting.  Your completed hours will be displayed at all times.</li>
            </ul>
        </Container>
    </div>
  )
}

const titleFont = {
    color: "rgb(35, 81, 133)",
    fontWeight: "700",
};

export default WhyChooseUs;