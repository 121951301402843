import React from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";
import { Link } from "react-router-dom";

export const Location = () => {
  return (
    <div>
      <Navbar1 />
      <div className="container pb-5">
        <p style={fontStyle}>
          <Link to="/" style={backFormat}>
            Back
          </Link>
        </p>
        <div className="row justify-content-center py-4">
          <h4>Our Location</h4>
        </div>
        <p style={fontStyle}>
          CEsimply is a tech company based out of Southern California. Two of
          our founders are California nurses which is why we have specialized in
          submitting nurse license renewals with the California BRN. We will
          soon expand this feature to other licenses and states. If you are a
          nurse residing outside of California and are interested in CEsimply
          submitting your CEUs to your state’s nursing licensure board, please
          reach out to us at support@cesimply.com or contact us{" "}
          <Link to="/Contact" style={linkFormat}>
            here
          </Link>{" "}
          so we can get the ball rolling even faster! If you are willing to help
          with this process, we might even pay your next license renewal fee.
        </p>
      </div>
      <Footer />
    </div>
  );
};

const fontStyle = {
  fontSize: "0.8em",
};

const backFormat = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "500",
};

const linkFormat = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "700",
};

export default Location;
