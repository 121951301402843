import React, { useContext } from "react";
import Alert from "react-bootstrap/Alert";

import AlertContext from "../context/alert/alertContext";

export const Alert1 = () => {
  const alertContext = useContext(AlertContext);

  return (
    alertContext.alerts.length > 0 &&
    alertContext.alerts.map((alert) => (
      <Alert key={alert.id} variant={`${alert.type}`}>
        {`${alert.msg}`}
      </Alert>
    ))
  );
};

export default Alert1;
