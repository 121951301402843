import React from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const About = () => {
  const aboutStructuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    url: "https://cesimply.com",
    logo: "https://cesimply.com/pictures/Light Blue Circle Business Logo.png",
  };

  const JSONschema = JSON.stringify(aboutStructuredData);

  return (
    <div>
      <Helmet>
        <title>About us | CEsimply</title>
        <meta name="description" content="About us here at CEsimply" />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>

      <Navbar1 />
      <div className="container">
        <p style={fontStyle}>
          <Link to="/" style={backFormat}>
            Back
          </Link>
        </p>
        <div className="row justify-content-center py-4">
          <h4>About us</h4>
        </div>
        <p style={question}>Who we are</p>
        <p style={answer}>
          CEsimply is a California based company started in 2020 by nurses and
          an IT team.
        </p>
        <br></br>
        <p style={question}>What we do</p>
        <p style={answer}>
          We provide an intuitive filing, tracking, and management system for
          all professions requiring continuing education hours. We do this with
          a mobile application and a website (coming soon).
        </p>
        <p style={answer}>
          Snap a photo of your CE document the moment you walk out of class and
          never have to think about it again. CEsimply will submit your CE
          documents to the Board of Registered Nursing when it's time to renew
          your nursing license (available for select licenses in some states).
          While we built CEsimply with nurses in mind, CE document storage is
          free and available for all job types.
        </p>
        <br></br>
        <p style={question}>Why we do it</p>
        <p className="pb-5" style={answer}>
          We believe all accredited individuals should get top notch CE service
          for close to free (we would make it completely free if we could!). You
          do enough between work, taking CE classes, and having a life that we
          believe our users shouldn’t have to spend any further energy on the CE
          process.
        </p>
      </div>
      <Footer />
    </div>
  );
};

const fontStyle = {
  fontSize: "0.8em",
};

const backFormat = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "500",
};

const question = {
  fontSize: "0.8em",
  fontWeight: "700",
  color: "rgb(35, 81, 133)",
};

const answer = {
  fontSize: "0.8em",
};

export default About;
