import React from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";
import { Link } from "react-router-dom";

export const WhyUs = () => {
  return (
    <div>
      <Navbar1 />
      <div className="container pb-5">
        <p>
          <Link to="/" style={backFormat}>
            Back
          </Link>
        </p>
        <div className="row justify-content-center py-4">
          <h4>Why choose us?</h4>
        </div>
        <p style={fontStyle}>
          For many of us who have to maintain professional licenses with
          Continuing Education, keeping track and submitting all of the
          documents can be daunting. Imagine this: you have just gotten married
          and moved into a beautiful new apartment overlooking the ocean with
          your new partner. The two of you are leisurely unpacking when you
          receive a love letter from the Board of Registered Nursing, demanding
          you submit all of your Continuing Education documents from 4 years
          ago. Lucky you! But wait…where did you pack your CEUs? Did you even
          pack them? Instead of scrambling to open the remaining 43 boxes hoping
          to find your CEUs buried in one, you reach for your phone. With the
          click of a few buttons, you send all of the your CEUs directly to your
          email address and then to the BRN. Sayonara stress!
        </p>
        <p style={fontStyle}>
          We’ve designed the app to be like your personal CE managing assistant.
          It will remind you of upcoming expiration dates as well as store,
          file, track and turn in your CEs for you! Yes, CEsimply will{" "}
          <nbsp></nbsp>
          <Link to="/Renew" style={linkFormat}>
            submit
          </Link>{" "}
          your nursing CEUs to the Board of Registered Nursing for you. After
          you've renewed with us, we'll give you a summary of your renewal and
          what CEs were submitted. Gone are the days of tediously typing out
          every provider number and class name when it’s time to renew your
          license. Any and all previous stored CEs will be accessible forever.
        </p>
        <p style={fontStyle}>
          Not convinced? Here are some other reasons why people choose us to
          track:
        </p>
        <p style={fontStyle}>
          <ul>
            <li>
              We are one of the few companies out there that lets you track multiple licenses and certifications. 
              Many professionals have 2 or 3 licenses as well as multiple certifications.  
              Continuing education can be applied to all and tracked clearly.
            </li>
            <li>
              We are the only company that will submit all of your licenses for
              a flat rate, regardless of how many licenses/certifications you
              have.
            </li>
            <li>
              We have the ability to submit for any license in any state*. If
              you don’t currently see your license/state in the list of places
              we support, simply send us a message to request it. With your
              help, we can get the ball rolling even faster. We may even pay
              your next renewal fee.
            </li>
            <li>
              Check out this{" "}
              <Link to="/BestFeaturesCETracker" style={linkFormat}>
                page
              </Link>{" "}
              to see a list of features any tracker should have.
            </li>
          </ul>
        </p>
        <p style={fontStyle}>
          *Plan to submit for all states that require CEs. For a list of states
          we can currently submit please see{" "}
          <Link to="/SubmissionList" style={linkFormat}>
            here
          </Link>
          . If you would like your license/state sooner rather than later,
          please contact us{" "}
          <Link to="/Contact" style={linkFormat}>
            here
          </Link>
          .
        </p>
      </div>
      <Footer />
    </div>
  );
};

const fontStyle = {
  fontSize: "0.8em",
};

const backFormat = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "500",
  fontSize: "0.8em",
};

const linkFormat = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "700",
};

export default WhyUs;
