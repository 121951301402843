import React from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const BestFeaturesCETracker = () => {
  const articleStructuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline:
      "What are the best features you should look for in a continuing education tracker (CE Tracker)?",
    image:
      "https://cesimply.com/pictures/blogPictures/bestFeaturesOfCETrackerHero.jpg",
    datePublished: "2022-03-16T08:00:11+00:00",
    dateModified: "2022-04-12T09:20:00+08:00",
    author: {
      "@type": "Person",
      name: "Michael Hwang",
    },
  };

  const JSONschema = JSON.stringify(articleStructuredData);

  return (
    <div>
      <Helmet>
        <title>
          9 Essential CE Tracker Features - Continuing Ed | CEsimply
        </title>
        <meta
          name="description"
          content="The 9 most important features to have in a continuing education tracker"
        />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>

      <Navbar1 />
      <div className="pb-5">
        <Container>
          <p>
            <Link to="/Blog" style={backFormat}>
              Back
            </Link>
          </p>
          <Row>
            <Col style={{ padding: "0 0 0 0" }}>
              <img
                src={articleStructuredData.image}
                alt="desk with papers"
                style={{
                  width: "100%",
                  height: "auto",
                }}
              ></img>
            </Col>
          </Row>
          <Row>
            <p style={titleStyle}>{articleStructuredData.headline}</p>
            <p style={paragraphStyle}>Published: March 16th, 2022</p>
            <p style={paragraphStyle}>
              So you’ve decided you would like to store your continuing
              education electronically (or need to) in order to keep all your CE
              certificates in order. But what makes a CE tracker good? What
              should it be able to do to help you feel like you can keep it all
              organized? Here is a list of items we feel is necessary in order
              of importance to replace that drawer.
            </p>
            <ol style={paragraphStyle}>
              <li style={numberListStyle}>
                The CE tracker can keep a copy of the certificate.
              </li>
              <p>
                This is critical because without this, you would still need to
                keep a hardcopy of the certificate. If you still have to keep
                the certificate, then what’s the point of transferring it to be
                electronic?
              </p>
              <li style={numberListStyle}>
                The CE tracker can easily export or print all of your
                certificates in the case of an audit.
              </li>
              <p>
                This goes hand in hand with number 3, but the point of having it
                electronic is to have a copy as a backup
              </p>
              <li style={numberListStyle}>
                The CE tracker will store the documents forever.
              </li>
              <p>
                Some audits may have been for education 6 years ago, the longer
                it can be safely kept the more peace of mind it brings.
              </p>
              <li style={numberListStyle}>Uploading a CE is fast and easy.</li>
              <p>
                Ideally it would be as simple as taking out your phone to take a
                picture of the CE and have it easily uploaded for safe storage.
                Sounds like a luxury feature, but without mobile scanning of the
                CE honestly it wont get scanned otherwise. No one is going to go
                home after class and scan an image of their certificate just to
                have it stored electronically.
              </p>
              <li style={numberListStyle}>
                Being able to keep track of which CE was used for which renewal
                year.
              </li>
              <p>
                If a CE tracker is going to keep 10 years of certificates, it
                will be important to know which ones were used for which
                renewal.
              </p>
              <li style={numberListStyle}>
                The CE tracker can take a CE and keep track of which license or
                certification it was used for.
              </li>
              <p>
                If you have multiple licenses or certifications, it can be
                difficult to see what CE is applied to what. A continuing
                education certiticate may have been used for multiple licenses
                or not be allowed for some.
              </p>
              <li style={numberListStyle}>
                The CE tracker has the ability to apply different hours to
                different licenses.
              </li>
              <p>
                A CE may be 3 hours for a specific license but may only count as
                2 pharmacology hours. The tracker should be able to allow for
                the flexibility and keep track of it.
              </p>
              <li style={numberListStyle}>
                The CE tracker could let me know if one of my licenses or
                certitications is about to expire.
              </li>
              <p>
                Nothing is worse than being surprised that the renewal is less
                than 2 months away and we haven't done any continuing education.
              </p>
              <li style={numberListStyle}>
                The CE tracker can total up how many hours I currently have.
              </li>
              <p>
                Last but not least! As the renewal date for a license or
                certification nears, we automatically start thinking how many
                more hours we need. The CE tracker could, at a glance, show us
                how many more we need.
              </p>
            </ol>
            <p style={paragraphStyle}>
              What do you think? Do you agree with our list? Let us know if you
              think there are others! The CEsimply app can do all of the above
              and more. Also check out this{" "}
              <Link to="/Renew" style={linkFormat}>
                page
              </Link>{" "}
              on how it can turn your CEs for you as well. It's already stored
              in there, might as save you some time!
            </p>
            <p style={{ fontSize: "0.7em", margin: "auto" }}>
              To download the app and start storing for free, click below.
            </p>
          </Row>
          <Row>
            <div
              className="row justify-content-center py-5"
              style={{ margin: "auto" }}
            >
              <a
                href="https://apps.apple.com/us/app/cesimply/id1582382064?itsct=apps_box_badge&amp;itscg=30200"
                style={appleButton}
              >
                <img
                  src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1630281600&h=8bd2c7e704bd2fe62db5aa45a0be6168"
                  alt="Download on the App Store"
                  style={appleImgStyle}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.cetracker&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                style={playButton}
              >
                <img
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  style={playImgStyle}
                />
              </a>
            </div>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

const backFormat = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "500",
};

const linkFormat = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "700",
};

const titleStyle = {
  paddingTop: "2em",
  fontWeight: "700",
};

const subheaderStyle1 = {
  fontSize: "0.9em",
};

const subheaderStyle2 = {
  fontSize: "0.8em",
};

const paragraphStyle = {
  fontSize: "0.7em",
};

const numberListStyle = {
  fontWeight: "700",
};

const appleButton = {
  display: "inline-block",
  overflow: "hidden",
  borderRadius: "13px",
};

const appleImgStyle = {
  borderRadius: "13px",
  width: "250px",
  height: "auto",
  padding: "20px",
};

const playButton = {
  display: "inline-block",
  overflow: "hidden",
  borderRadius: "13px",
};

const playImgStyle = {
  borderRadius: "13px",
  width: "243px",
  height: "110px",
  paddingTop: "5px",
};

export default BestFeaturesCETracker;
