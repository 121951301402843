import { ADD_CE, LOAD_CE } from "../types";

export default (state, action) => {
  switch (action.type) {
    case LOAD_CE:
      return {
        ...state,
        ce: action.paylod,
      };
    case ADD_CE:
      return {
        ...state,
        redirect: "/Dashboard",
        // ^^ This will need to be the CE documents page
      };
  }
};
