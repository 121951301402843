import React, { useEffect, useContext } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import firebase from "firebase/app";
import "firebase/firestore";

import AuthContext from "../context/auth/authContext";

export const LicenseItem = (props) => {
  const authContext = useContext(AuthContext);

  const { user } = authContext;

  const uid = user.uid;

  const {
    licenseType,
    licenseState,
    licenseNum,
    licenseExpiration,
    totalCEHours,
  } = props.license[1];

  const stateList = {
    Arizona: "AZ",
    Alabama: "AL",
    Alaska: "AK",
    Arkansas: "AR",
    California: "CA",
    Colorado: "CO",
    Connecticut: "CT",
    Delaware: "DE",
    Florida: "FL",
    Georgia: "GA",
    Hawaii: "HI",
    Idaho: "ID",
    Illinois: "IL",
    Indiana: "IN",
    Iowa: "IA",
    Kansas: "KS",
    Kentucky: "KY",
    Louisiana: "LA",
    Maine: "ME",
    Maryland: "MD",
    Massachusetts: "MA",
    Michigan: "MI",
    Minnesota: "MN",
    Mississippi: "MS",
    Missouri: "MO",
    Montana: "MT",
    Nebraska: "NE",
    Nevada: "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    Ohio: "OH",
    Oklahoma: "OK",
    Oregon: "OR",
    Pennsylvania: "PA",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    Tennessee: "TN",
    Texas: "TX",
    Utah: "UT",
    Vermont: "VT",
    Virginia: "VA",
    Washington: "WA",
    "West Virginia": "WV",
    Wisconsin: "WI",
    Wyoming: "WY",
  };

  const statusGreen = {
    flexShrink: 1,
    left: "1rem",
    borderRadius: "12rem",
    backgroundColor: "rgba(195, 238, 224, 1)",
    justifyContent: "center",
    alignSelf: "flex-start",
    marginBottom: "0.5rem",
  };

  const statusTextGreen = {
    paddingLeft: "4rem",
    paddingRight: "4rem",
    marginTop: "1rem",
    marginBottom: "1rem",
    fontSize: "0.7rem",
    color: "rgba(12, 44, 33, 1)",
    fontWeight: "500",
  };

  const statusYellow = {
    flexShrink: 1,
    left: "1rem",
    borderRadius: "12rem",
    backgroundColor: "rgba(249,237,201,1)",
    justifyContent: "center",
    alignSelf: "flex-start",
    marginBottom: "0.2rem",
  };

  const statusTextYellow = {
    paddingLeft: "4rem",
    paddingRight: "4rem",
    marginTop: "1rem",
    marginBottom: "1rem",
    fontSize: "0.7rem",
    color: "rgba(98,50,31,1)",
    fontWeight: "500",
  };

  const statusRed = {
    flexShrink: 1,
    left: "1rem",
    borderRadius: "12rem",
    backgroundColor: "rgba(255,224,225,1)",
    justifyContent: "center",
    alignSelf: "flex-start",
    marginBottom: "1rem",
  };

  const statusTextRed = {
    paddingLeft: "4rem",
    paddingRight: "4rem",
    marginTop: "1rem",
    marginBottom: "1rem",
    fontSize: "1rem",
    color: "rgba(123,53,54,1)",
    fontWeight: "500",
  };

  const getStateAcronym = () => {
    return stateList[licenseState];
  };

  const getShortenedTitle = () => {
    switch (licenseType) {
      case "Licensed Vocational Nurse (LVN)":
        return "LVN";
      case "Registered Nurse (RN)":
        return "RN";
      default:
        return "";
    }
  };

  const getStatus = () => {
    const now = new Date().getTime();
    const expiration = new Date(licenseExpiration).getTime();
    const diffInDays = (expiration - now) / (1000 * 3600 * 24);
    if (diffInDays > 90) {
      return (
        <div style={statusGreen}>
          <p style={statusTextGreen}>Up to date</p>
        </div>
      );
    } else if (diffInDays <= 90 && diffInDays > 0) {
      return (
        <div style={statusYellow}>
          <p style={statusTextYellow}>Expiring soon</p>
        </div>
      );
    } else {
      return (
        <div style={statusRed}>
          <p style={statusTextRed}>Overdue</p>
        </div>
      );
    }
  };

  const stateAcronym = getStateAcronym(licenseState);
  const shortenedLicense = getShortenedTitle(licenseType);

  const licenseTitle = shortenedLicense + ` License (${stateAcronym})`;

  useEffect(() => {
    getStateAcronym(licenseState);
    getShortenedTitle(licenseType);
  }, []);

  const addCE = () => {
    console.log("added a CE");
  };

  return (
    <div className="card my-1">
      <div className="card-body">
        {/* Ask peter, how to I put "shortenedLicense + " License(${stateAcronym})"" on the line below where it says "licenseTitle" */}
        <h4 className="card-title" style={{ fontSize: "1.2rem" }}>
          {licenseTitle}
        </h4>
        <p className="card-subtitle" style={cardText}>
          License #: {licenseNum}
        </p>
        <p>Exp: {licenseExpiration}</p>
        {getStatus()}
        {/* <ProgressBar now={50} /> */}
        <div class="progress" style={{ height: "15px" }}>
          <div
            class="progress-bar"
            role="progressbar"
            style={progressBar}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <button className="button-outline" style={buttonStyle} onClick={addCE}>
          Add CE
        </button>
      </div>
    </div>
  );
};

// Add the syntax of peter's bracket variables to the syntax index:
// let licenseTitle = '';
// if (licenseData['licenseType'] === 'Other') {
//     const stateAcronym = getStateAcronym(licenseData['licenseState'])
//     licenseTitle = `${licenseData['otherLicenseType']} License (${stateAcronym})`;
// }
// else {
//     const licenseType = getShortenedTitle(licenseData['licenseType']);
//     const stateAcronym = getStateAcronym(licenseData['licenseState'])
//     licenseTitle = licenseType + ` License (${stateAcronym})`;
// }

// Also add licenseTitle syntax of how to use ${variable} syntax

export default LicenseItem;

const cardText = {
  fontSize: "0.9rem",
};

const buttonStyle = {
  fontSize: "1.5em",
};

const progressBar = {
  backgroundColor: "rgb(35, 81, 133)",
  width: "50%",
};
