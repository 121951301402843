exports.getConfig = () => {
  const NODE_ENV = process.env.NODE_ENV || "production";
  let config = {};
  if (NODE_ENV === "production" || NODE_ENV === "prod") {
    config = prodConfig;
    config.isProd = true;
  } else if (
    NODE_ENV === "development" ||
    NODE_ENV === "dev" ||
    NODE_ENV === "local"
  ) {
    config = devConfig;
    config.isDev = true;
  }
  config.NODE_ENV = NODE_ENV;
  return config;
};

const devConfig = {
  stripeOptions: {
    publishableKey: "pk_test_cGkQlPvs51UzF6W4lfRdq7gu00faHg5vS",
    androidPayMode: "test", //Android only
  },
  stripeKey: "sk_test_5CC56jQrRIwH2yGJKTdu1N3d00cVX8JOJU",
  functionsURI: "http://localhost:5001/cetracker-2de23/us-central1",
};

const prodConfig = {
  stripeOptions: {
    publishableKey: "pk_live_RK1UnbT1HzzKtqdk4zR6XgJj00mznskYaR",
    androidPayMode: "production",
  },
  stripeKey: "sk_live_ptttOeewt68r6AVwffM7F3TN00uArUiFXZ",
  functionsURI: "https://us-central1-cetracker-2de23.cloudfunctions.net/",
};
