import React from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";

export const Terms = () => {
    return (
        <div>
            <Navbar1 />
            <div style={{ marginBottom: 100 }}>
                <h1 style={center}>Success!</h1>
                <p style={center}>
                    We appreciate your business!
                    If you have any questions, please email
                    <a href="mailto:support@cesimply.com"> support@cesimply.com</a>.
                </p>
            </div>
            <Footer />
        </div>
    );
};

const center = {
    margin: 'auto',
    width: '50%',
    marginBottom: 60,
};
const borderStyle = {
    height: "407rem",
    width: "100%",
    border: "none",
};

export default Terms;
