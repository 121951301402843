import React from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";

export const Terms = () => {
    return (
        <div>
            <Navbar1 />
            <div style={center}>
                <p style={center}>
                    Click below to pay your NP license renewal fee.
                </p>
                <form style={center} action="https://us-central1-cetracker-2de23.cloudfunctions.net/createCheckoutSession" method="POST">
                    <button type="submit" style={buttonColor}>Checkout</button>
                </form>
                {/* <form style={center} action="http://localhost:5000/cetracker-2de23/us-central1/createCheckoutSession" method="POST">
                    <button type="submit" style={buttonColor}>Checkout</button>
                </form> */}
            </div>
            <Footer />
        </div>
    );
};

const center = {
    margin: 'auto',
    width: '50%',
    textAlign: 'center',
    marginBottom: 60,
};
const borderStyle = {
    height: "407rem",
    width: "100%",
    border: "none",
};

const buttonColor = {
    color: "white",
    backgroundColor: "rgb(35, 81, 133)",
    fontSize: "1em",
    borderRadius: "5px",
};

export default Terms;
