import React from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";
import { Link } from "react-router-dom";

export const SubmissionList = () => {
  return (
    <div>
      <Navbar1 />
      <div className="container-fluid" style={{ marginBottom: "8rem" }}>
        <div className="row justify-content-center py-4">
          <h4 className="text-center">States and licenses we submit for:</h4>
        </div>

        <div className="row justify-content-start px-3">
          <div className="col-md-4">
            <p style={listFont}>California RN - BreEZe</p>
            <p style={listFont}>California LVN - BreEZe</p>
          </div>
        </div>

        <div className="row justify-content-start px-3">
          <p style={{ fontSize: "0.8em" }}>
            Don't see your license here? Not to worry, we may already be working
            on your license and state. If you'd like to work with us on getting
            your license on this list, please contact us{" "}
            <Link to="/Contact" style={linkStyle}>
              here
            </Link>
            . We may even pay your next renewal fee!
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const listFont = {
  fontSize: "0.8em",
  fontWeight: "700",
};

const linkStyle = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "900",
};

export default SubmissionList;
