import React from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";
import { Link } from "react-router-dom";

export const Testimonials = () => {
  return (
    <div>
      <Navbar1 />
      <div className="container pb-5">
        <Link to="/" style={backFormat}>
          Back
        </Link>
        <div className="row justify-content-center py-4">
          <h4>Testimonials</h4>
        </div>
        <p style={fontStyle}>
          I've renewed my license with this app twice now, I'm a RN in
          California and I have to say, having this available has honestly taken
          away ALL my anxiety away regarding my renewal. I'm already done with
          my CEs for for next year and I'm just waiting for my renewal to come.
          <br></br>
          <span style={nameStyle}>- Michael H. RN, BSN</span>
        </p>
        <p style={fontStyle}>
          Uh, never have to deal with my renewal again? YES, OF COURSE <br></br>
          <span style={nameStyle}>- Joseph J. RN BSN</span>
        </p>
        <p style={fontStyle}>
          So my wife just does them for me usually. And she's not even a nurse.
          I just can't with the BRN website, it gives me SO MUCH ANXIETY to
          answer all those questions. This is worth every penny. <br></br>
          <span style={nameStyle}>- Chase P. RN</span>
        </p>
        <p style={fontStyle}>
          Worked like a charm! This is amazing! <br></br>
          <span style={nameStyle}>- Lyndsay C. RN BSN PHN</span>
        </p>
      </div>
      <Footer />
    </div>
  );
};

const fontStyle = {
  fontSize: "0.8em",
};

const nameStyle = {
  fontWeight: "700",
};

const backFormat = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "500",
};

export default Testimonials;
