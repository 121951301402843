import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Hero from "../layout/Hero";
import Navbar1 from "../layout/Navbar1";
import HowItWorks from "../layout/HowItWorks";
import WhyChooseUs from "../layout/WhyChooseUs";
import Services from "../layout/Services";
import Footer from "../layout/Footer";

export const Home = () => {
  const softwareAppStructuredData = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    name: "CE Tracker | CEsimply",
    operatingSystem: "iOS and ANDROID",
    applicationCategory: "BusinessApplication",
    review: [
      {
        "@type": "Review",
        reviewRating: {
          "@type": "Rating",
          ratingValue: "5",
        },
        author: {
          "@type": "Person",
          name: "Chase P.",
        },
        reviewBody:
          "This app is awesome. It has taken all my anxiety with renewing my license away",
      },
      {
        "@type": "Review",
        reviewRating: {
          "@type": "Rating",
          ratingValue: "4",
        },
        author: {
          "@type": "Person",
          name: "Lyndsey C.",
        },
        reviewBody:
          "I love the convenience.  I also like that I don't have to worry about this ever being deleted.",
      },
    ],
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.5",
      ratingCount: "2",
    },
    offers: {
      "@type": "Offer",
      price: "34.99",
      priceCurrency: "USD",
    },
  };

  const JSONschema = JSON.stringify(softwareAppStructuredData);

  return (
    <Fragment>
      <Helmet>
        <title>
          Continuing education tracker - A CE management app | CEsimply
        </title>
        <meta
          name="description"
          content="Don't waste any more time dealing with your continuing education, CEsimply offers a free CE tracker that can store, track and submit your CEs for you!"
        />
        <meta
          property="og:title"
          content="CEsimply-free continuing education tracker"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="CEsimply offers a free continuing education tracker that stores, tracks and submits your CEs for you!"
        />
        <meta property="og:url" content="https://www.cesimply.com/" />
        <meta
          property="og:image"
          content="https://www.cesimply.com/pictures/hero.png"
        />
        <meta name="twitter:card" content="Free continuing education tracker" />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>
      <Navbar1 />
      <Hero />
      <HowItWorks />
      <WhyChooseUs />
      <Footer />
    </Fragment>
  );
};

export default Home;
