import React, { useState, useContext } from "react";
import { useParams } from "react-router";
import Carousel from "react-bootstrap/Carousel";
import AlertContext from "../context/alert/alertContext";
import firebase from "firebase/app";
import "firebase/firestore";

import logo2 from "../tools/pictures/logo2.png";

export const Survey = (props) => {
  const { uid } = useParams();

  const alertContext = useContext(AlertContext);

  const { setAlert } = alertContext;

  const [survey, setSurvey] = useState({
    renewLicenseYes: "",
    renewLicenseNo: "",
    tooHigh: "",
    tooLow: "",
    justRight: "",
    recommendCoworkersYes: "",
    recommendCoworkersNo: "",
  });

  const [surveyFreeText, setSurveyFreeText] = useState({
    renewLicenseWhyNot: "",
    recommendCoworkersWhyNot: "",
    additionalFeatures: "",
    firstName: "",
    lastName: "",
    email: "",
  });

  const [index, setIndex] = useState(0);

  const {
    renewLicenseYes,
    renewLicenseNo,
    tooHigh,
    tooLow,
    justRight,
    recommendCoworkersYes,
    recommendCoworkersNo,
  } = survey;
  const {
    additionalFeatures,
    renewLicenseWhyNot,
    recommendCoworkersWhyNot,
    firstName,
    lastName,
    email,
  } = surveyFreeText;

  const onChangeRenewLicense = (e) => {
    if (e.target.id == "renewLicenseYes") {
      setSurvey({
        ...survey,
        ["renewLicenseYes"]: true,
        ["renewLicenseNo"]: false,
      });
    } else {
      setSurvey({
        ...survey,
        ["renewLicenseYes"]: false,
        ["renewLicenseNo"]: true,
      });
    }
  };

  const onChangePricesReasonable = (e) => {
    if (e.target.id == "tooHigh") {
      setSurvey({
        ...survey,
        ["tooHigh"]: true,
        ["tooLow"]: false,
        ["justRight"]: false,
      });
    } else if (e.target.id == "tooLow") {
      setSurvey({
        ...survey,
        ["tooHigh"]: false,
        ["tooLow"]: true,
        ["justRight"]: false,
      });
    } else {
      setSurvey({
        ...survey,
        ["tooHigh"]: false,
        ["tooLow"]: false,
        ["justRight"]: true,
      });
    }
  };

  const onChangeRecommendCoworkers = (e) => {
    if (e.target.id == "recommendCoworkersYes") {
      setSurvey({
        ...survey,
        ["recommendCoworkersYes"]: true,
        ["recommendCoworkersNo"]: false,
      });
    } else {
      setSurvey({
        ...survey,
        ["recommendCoworkersYes"]: false,
        ["recommendCoworkersNo"]: true,
      });
    }
  };

  const onChangeFreeText = (e) =>
    setSurveyFreeText({ ...surveyFreeText, [e.target.id]: e.target.value });

  const nextSlide = () => {
    setIndex(index + 1);
  };

  const previousSlide = () => {
    setIndex(index - 1);
  };

  const onSubmit = () => {
    // example of syntax for console loggin the state
    // console.log({ renewLicenseYes });
    setAlert(
      "Submitted! Winner of survey will be announced via your email",
      "success"
    );

    const surveyAnswersObj = {
      renewLicenseYes,
      renewLicenseNo,
      renewLicenseWhyNot,
      tooHigh,
      tooLow,
      justRight,
      recommendCoworkersYes,
      recommendCoworkersNo,
      recommendCoworkersWhyNot,
      additionalFeatures,
      firstName,
      lastName,
      email,
      uid: uid,
    };

    const triggerSurveyFunction = firebase
      .functions()
      .httpsCallable("writeSurvey");
    triggerSurveyFunction(surveyAnswersObj)
      .then((result) => {
        console.log(JSON.stringify(result));
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });

    props.history.push("/");
  };

  return (
    <div
      className="container pb-5 col-11"
      style={{ backgroundColor: "rgb(35, 81, 133)" }}
    >
      <div className="row justify-content-center">
        <img src={logo2} alt="Logo" className="pt-lg-1 pb-1"></img>
      </div>
      <div className="row">
        <div className="col" style={{ textAlign: "center" }}></div>
      </div>
      <div className="row justify-content-center">
        <div className="col-10 pt-4" style={surveyForm}>
          <Carousel
            interval={null}
            touch={false}
            wrap={false}
            controls={false}
            activeIndex={index}
            className="pb-5"
          >
            <Carousel.Item>
              <p>1. Would you use this app to renew your license?</p>
              {/* <p>Your email is: {emailName}</p> */}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="renewLicense"
                  id="renewLicenseYes"
                  value="renewLicenseYes"
                  onChange={onChangeRenewLicense}
                ></input>
                <label className="form-check-label" for="renewLicenseYes">
                  Yes
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="renewLicense"
                  id="renewLicenseNo"
                  value="renewLicenseNo"
                  onChange={onChangeRenewLicense}
                ></input>
                <label className="form-check-label" for="renewLicenseNo">
                  No
                </label>
              </div>
              <br></br>
              <p>Why not?</p>
              <textarea
                class="form-control"
                id="renewLicenseWhyNot"
                value={renewLicenseWhyNot}
                rows="4"
                onChange={onChangeFreeText}
                disabled={renewLicenseNo == false}
              ></textarea>
              <br></br>
              <button onClick={nextSlide} style={nextButton}>
                Next
              </button>
            </Carousel.Item>
            <Carousel.Item>
              <p>2. Do you think our prices are reasonable?</p>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="pricesReasonable"
                  id="tooHigh"
                  value={tooHigh}
                  onChange={onChangePricesReasonable}
                ></input>
                <label className="form-check-label" for="tooHigh">
                  Too High
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="pricesReasonable"
                  id="tooLow"
                  value={tooLow}
                  onChange={onChangePricesReasonable}
                ></input>
                <label className="form-check-label" for="tooLow">
                  Too Low
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="pricesReasonable"
                  id="justRight"
                  value={justRight}
                  onChange={onChangePricesReasonable}
                ></input>
                <label className="form-check-label" for="justRight">
                  Just Right
                </label>
              </div>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <button onClick={previousSlide} style={previousButton1}>
                Previous
              </button>
              <button onClick={nextSlide} style={nextButton1}>
                Next
              </button>
            </Carousel.Item>
            <Carousel.Item>
              <p>3. Would you recommend this app to your coworkers?</p>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="recommendCoworkers"
                  id="recommendCoworkersYes"
                  value={recommendCoworkersYes}
                  onChange={onChangeRecommendCoworkers}
                ></input>
                <label className="form-check-label" for="recommendCoworkersYes">
                  Yes
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="recommendCoworkers"
                  id="recommendCoworkersNo"
                  value={recommendCoworkersNo}
                  onChange={onChangeRecommendCoworkers}
                ></input>
                <label className="form-check-label" for="recommendCoworkersNo">
                  No
                </label>
              </div>
              <br></br>
              <p>Why not?</p>
              <textarea
                class="form-control"
                id="recommendCoworkersWhyNot"
                value={recommendCoworkersWhyNot}
                rows="4"
                onChange={onChangeFreeText}
                disabled={recommendCoworkersNo == false}
              ></textarea>
              <br></br>
              <button onClick={previousSlide} style={previousButton}>
                Previous
              </button>
              <button onClick={nextSlide} style={nextButton}>
                Next
              </button>
            </Carousel.Item>
            <Carousel.Item>
              <p>
                4. What additional features do you wish this app had regarding
                your CE process? e.g. Autofill CE details from scanned
                documents.
              </p>
              <textarea
                class="form-control"
                id="additionalFeatures"
                value={additionalFeatures}
                rows="4"
                onChange={onChangeFreeText}
              ></textarea>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <button onClick={previousSlide} style={previousButton2}>
                Previous
              </button>
              <button onClick={nextSlide} style={nextButton2}>
                Next
              </button>
            </Carousel.Item>
            <Carousel.Item>
              <p>
                Please enter your name and email address if you wish to be
                entered into the raffle*:
              </p>
              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First"
                    id="firstName"
                    value={firstName}
                    onChange={onChangeFreeText}
                  ></input>
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last"
                    id="lastName"
                    value={lastName}
                    onChange={onChangeFreeText}
                  ></input>
                </div>
              </div>
              <br></br>
              <div className="row">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  id="email"
                  value={email}
                  onChange={onChangeFreeText}
                ></input>
              </div>
              <br></br>
              <p style={{ fontSize: "0.6em" }}>
                *Even if your license is not in California, you will still be
                eligible for the raffle.
              </p>
              <br></br>
              <br></br>
              <button onClick={previousSlide} style={previousButton2}>
                Previous
              </button>
              <button onClick={onSubmit} style={nextButton2}>
                Submit
              </button>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Survey;

const surveyForm = {
  backgroundColor: "rgba(202, 213, 226, 1)",
  borderRadius: "15px",
};

const nextButton = {
  color: "#fff",
  backgroundColor: "rgb(35, 81, 133)",
  borderColor: "rgb(35, 81, 133)",
  borderRadius: "10px",
  fontSize: "inherit",
  float: "right",
};

// moving the button up and down on the page with <br></br> didn't exactly align so using margin to do it instead.
const nextButton1 = {
  color: "#fff",
  backgroundColor: "rgb(35, 81, 133)",
  borderColor: "rgb(35, 81, 133)",
  borderRadius: "10px",
  fontSize: "inherit",
  float: "right",
  marginTop: "0.4em",
};

const nextButton2 = {
  color: "#fff",
  backgroundColor: "rgb(35, 81, 133)",
  borderColor: "rgb(35, 81, 133)",
  borderRadius: "10px",
  fontSize: "inherit",
  float: "right",
  marginTop: "1em",
};

const previousButton = {
  color: "#fff",
  backgroundColor: "rgb(35, 81, 133)",
  borderColor: "rgb(35, 81, 133)",
  borderRadius: "10px",
  fontSize: "inherit",
};

const previousButton1 = {
  color: "#fff",
  backgroundColor: "rgb(35, 81, 133)",
  borderColor: "rgb(35, 81, 133)",
  borderRadius: "10px",
  fontSize: "inherit",
  marginTop: "0.4em",
};

const previousButton2 = {
  color: "#fff",
  backgroundColor: "rgb(35, 81, 133)",
  borderColor: "rgb(35, 81, 133)",
  borderRadius: "10px",
  fontSize: "inherit",
  marginTop: "1em",
};

const formField = {
  fontSize: "0.7em",
  backgroundColor: "rgba(248, 251, 252, 1)",
};

const buttonColor = {
  color: "white",
  backgroundColor: "rgb(35, 81, 133)",
  fontSize: "1em",
};
