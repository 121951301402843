import React, { useReducer, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import CeContext from "./ceContext";
import ceReducer from "./ceReducer";
import AuthContext from "../auth/authContext";
import firebase from "firebase/app";
import "firebase/firestore";
import { LOAD_CE, ADD_CE, MODIFY_CE, DELETE_LICENSE } from "../types";

const CeState = (props) => {
  const authContext = useContext(AuthContext);

  const { user } = authContext;

  const initialState = {
    ce: {},
    isEmpty: true,
    redirect: null,
  };

  const [state, dispatch] = useReducer(ceReducer, initialState);

  //Load CE
  const getCeData = () => {
    const uid = user.uid;
    const db = firebase.firestore();
    db.collection("users")
      .doc(uid)
      .collection("CEs")
      .doc("CEData")
      .get()
      .then((response) => {
        const data = response.data();

        if (data) {
          dispatch({
            type: LOAD_CE,
            payload: data,
          });
        } else {
          console.log("could not retrieve CE data");
        }
      })
      .catch((error) => {
        console.log("caught error from firebase", error);
      });
  };

  //Add CE
  const addNewCE = (ceData) => {
    const ceId = uuidv4();
    ceData.id = ceId;
    const ceObj = {
      [ceId]: ceData,
    };
    const uid = user.uid;
    const db = firebase.firestore();
    db.collection("users")
      .doc(uid)
      .collection("CEs")
      .doc("CEData")
      .set(ceObj, { merge: true })
      .then(function () {
        console.log("CE document written successfully");
        dispatch({ type: ADD_CE });
        getCeData();
      })
      .catch(function (error) {
        console.error("error writing CE document", error);
      });
  };

  //Modify CE

  //Delete CE

  return (
    <CeContext.Provider
      value={{
        ce: state.ce,
        getCeData,
        addNewCE,
      }}
    >
      {props.children}
    </CeContext.Provider>
  );
};

export default CeState;
