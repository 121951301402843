import React from "react";
import { Link } from "react-router-dom";

// To do: make the plans boxes hover and clickable to start an account

export const Plans = () => {
  return (
    <div className="container">
      <div className="row justify-content-center py-4">
        <h4>Pick your Plan</h4>
      </div>
      <div className="row">
        <div
          className="col-lg-6 justify-content-around"
          style={{ marginBottom: "1rem" }}
        >
          <div style={pricingBox}>
            <h3 className="pt-3">
              $14.99/<span style={{ fontSize: "0.75em" }}>yr</span>
            </h3>
            <p style={{ fontSize: "0.8em" }}>
              <br></br>
            </p>
            <h5>Storage Plan</h5>
            <br></br>
            <br></br>
            <br></br>
            <i className="material-icons md-24" style={checkmark}>
              done
            </i>
            <span style={planFeatures}>Unlimited CE Storage</span>
            <br></br>
            <i className="material-icons md-24" style={checkmark}>
              done
            </i>
            <span style={planFeatures}>
              Reminders for expiring licenses/certifications
            </span>
            <br></br>
            <i className="material-icons md-24" style={checkmark}>
              done
            </i>
            <span style={planFeatures}>
              Easy scan and upload of CE
            </span>
            <br></br>
            <i className="material-icons md-24" style={checkmark}>
              done
            </i>
            <span style={planFeatures}>
              Easily export documents for an audit
            </span>
            <br></br>
            <i className="material-icons md-24" style={checkmark}>
              done
            </i>
            <span style={planFeatures}>
              Available option to pay $29.99 per license renewal*.  Pick and choose which license to renew.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <p style={{ fontSize: "0.6em" }}>
              *Available for select licenses in select states
            </p>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
          {/* <div style={footerBox}>
            <Link to="/Signup" style={signupLinkStyle}>
              Start Free Plan
            </Link>
          </div> */}
        </div>

        <div
          className="col-lg-6 justify-content-around"
          style={{ marginBottom: "1rem" }}
        >
          <div style={pricingBox}>
            <h3 className="pt-3">
              $24.99/<span style={{ fontSize: "0.75em" }}>yr</span>
            </h3>
            <p style={{ fontSize: "0.8em" }}>
              <br></br>
            </p>
            <h5>Concierge Plan</h5>
            <span style={{ fontSize: "0.8em" }}>(Storage and Renewals)</span>
            <br></br>
            <br></br>
            <br></br>
            <i className="material-icons md-24" style={checkmark}>
              done
            </i>
            <span style={planFeatures}>Unlimited CE Storage</span>
            <br></br>
            <i className="material-icons md-24" style={checkmark}>
              done
            </i>
            <span style={planFeatures}>
              All the features of storage plan
            </span>
            <br></br>
            <i className="material-icons md-24" style={checkmark}>
              done
            </i>
            {/* add link for "how" we submit their CEs */}
            <span style={planFeatures}>
              Unlimited license renewals through CEsimply app*
            </span>
            <br></br>
            <i className="material-icons md-24" style={checkmark}>
              done
            </i>
            {/* add link for "how" we submit their CEs */}
            <span style={planFeatures}>
              Complete end to end continuing education management
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <p style={{ fontSize: "0.6em" }}>
              *Available for select licenses in select states
            </p>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
          {/* <div style={footerBox}>
            <Link to="/Signup" style={signupLinkStyle}>
              Start Concierge Plan
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Plans;

const pricingBox = {
  borderColor: "rgb(35, 81, 133)",
  borderStyle: "solid",
  borderWidth: "3px",
  textAlign: "center",
  height: "85%",
};

const footerBox = {
  borderColor: "rgb(35, 81, 133)",
  borderStyle: "solid",
  borderRadius: "20px",
  backgroundColor: "rgba(202, 213, 226, 1)",
  marginBottom: "2rem",
  borderTop: "none",
  textAlign: "center",
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem",
};

const checkmark = {
  color: "rgba(104, 212, 176, 1)",
};

const planFeatures = {
  fontSize: "0.8em",
  color: "rgb(118, 113, 113)",
};

const signupLinkStyle = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
};
