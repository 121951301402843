import React from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";

export const Refund = () => {
  return (
    <div>
      <Navbar1 />
      <div className="row justify-content-center py-4">
        <h4>Refund Policy</h4>
      </div>
      <div style={{ padding: "0em 6em 2em" }}>
        <p style={{ color: "grey", fontSize: "0.7em" }}>
          Last updated June 25, 2021
        </p>
        <p style={fontStyle}>
          Thank you for your purchase. We hope you are happy with your purchase.
          However, if you are not completely satisfied with your purchase for
          any reason, you may request a cancelation of your account with a full
          refund. Please see below for more information on our refund policy.{" "}
        </p>
        <p style={headerStyle}>Refund Process</p>
        <p style={fontStyle}>
          All returns must be requested by email to support@CEsimply.com
          within thirty (30) days of the purchase date. After receiving your
          cancelation and refund request, we will process your return. Please
          allow at least fourteen (14) days from the receipt of your item to
          process your return. Refunds may take 1-2 billing cycles to appear on
          your credit card statement, depending on your credit card company. We
          will notify you by email when your refund has been processed.
        </p>
        <p style={headerStyle}>Exceptions</p>
        <p style={fontStyle}>The following items cannot be refunded:</p>
        <ul style={fontStyle}>
          <li>
            Concierge plans that were purchased more than thirty (30) days prior
            to requesting a cancellation and refund are not eligible for a
            refund.
          </li>
          <li>
            Accounts that have submitted their license renewal to the licensing
            board through CEsimply during the thirty (30) grace period are not
            eligible for a refund.
          </li>
        </ul>
        <p style={headerStyle}>Questions</p>
        <p style={fontStyle}>
          If you have any questions concerning our return policy, please contact
          us at:<br></br>support@CEsimply.com.{" "}
        </p>
      </div>
      <Footer />
    </div>
  );
};

const fontStyle = {
  fontSize: "0.6em",
};

const headerStyle = {
  fontSize: "0.9em",
};

export default Refund;
