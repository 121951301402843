import React from "react";
import Navbar1 from "../layout/Navbar1";
import Footer from "../layout/Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import nurseBurnoutHero from "../tools/pictures/nurseBurnoutHero.jpg";

export const NursingBurnout = () => {
  const articleStructuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: "Nursing Burnout - Are you currently experiencing it?",
    image:
      "https://cesimply.com/pictures/blogPictures/howTo/nurseBurnoutHero.jpg",
    datePublished: "2022-03-16T08:00:11+00:00",
    dateModified: "2022-04-12T09:20:00+08:00",
    author: {
      "@type": "Person",
      name: "Michael Hwang",
    },
  };

  const JSONschema = JSON.stringify(articleStructuredData);

  return (
    <div>
      <Helmet>
        <title>Nursing Burnout - Is it happening to you? | CEsimply</title>
        <meta
          name="description"
          content="Could nursing burnout be happening to you?  The signs are very subtle"
        />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>

      <Navbar1 />
      <div className="pb-5">
        <Container>
          <p>
            <Link to="/Blog" style={backFormat}>
              Back
            </Link>
          </p>
          <Row>
            <Col style={{ padding: "0 0 0 0" }}>
              <img
                src={nurseBurnoutHero}
                alt="girl frustrated"
                style={{
                  width: "100%",
                  height: "auto",
                }}
              ></img>
            </Col>
          </Row>
          <Row>
            <p style={titleStyle}>{articleStructuredData.headline}</p>
            <p style={paragraphStyle}>
              Something that wasn’t spoken to me much in nursing school is nurse
              burnout. I think in recent years, however, due to the big changes
              in the health care system from the Covid-19 pandemic, many of us
              nurses have experienced some level of burnout. My story of burnout
              begins long before the pandemic though, as early as my first year
              of nursing. I wouldn’t have labeled it as “burnout” at the time,
              but with each passing year I would come closer to the realization
              that I was in fact experiencing the ever-common, health care
              worker burnout. Since it wasn’t discussed much, it took a while
              for me to realize what it was. It eventually pushed me to leave my
              inpatient ICU role and move to an outpatient nursing position that
              has reminded me that I can have energy on my off days, free time
              to spend with family and friends, and actually enjoy going to
              work.
            </p>
            <p style={paragraphStyle}>
              One of the first symptoms of burnout I began to experience was the
              Sunday Night Blues, except because I was a nurse with no regular
              weekends, it was more like every night before work blues. I would
              spend most of the day before dreading when I would have to get
              ready for work the following day. Then the insomnia came and it
              eventually led to exhaustion and disruption of normal activities
              of daily living. Because the days of work were so intense and
              busy, there was incredible pressure to get to sleep and sleep
              well, which would many times cause the insomnia to get worse. I
              knew that if I didn’t rest well, I wouldn’t perform well at work
              which could cause me to be unsafe. Or I would be too exhausted
              come evening time where I would race to pick up my children from
              child care and get them into bed, allowing me to finally sit down
              at 2130 to begin eating dinner. Some days I would be too exhausted
              and end up skipping the meal altogether. The day after work
              usually wasn’t much better, I’d spend the day attempting to go to
              the store or accomplish something but would eventually wind up
              with me lounging in my pajamas feeling guilty about being too
              tired to leave the house.
            </p>
            <p style={paragraphStyle}>
              I knew something was wrong and that I couldn’t do this forever,
              but when you’re in it you just sort of cope with it. It wasn’t
              until I was out of it I realized how much it had affected me. The
              exhaustion, depression and anxiety became my new norm. My rhythm
              involved me trying to get through each shift, resting so I could
              get through the next shift. This cycle left me very little at the
              end of the day for myself or my family.
            </p>
            <p style={paragraphStyle}>
              Due to postpartum complications, I was forced to leave ICU nursing
              and transition to a less physically taxing RN role. Hospice
              nursing had always piqued an interest in the back of my mind but
              all of the “what if’s” of leaving bedside would scream louder than
              any interest. What if I lose all of my skills? What if I hate it?
              What if the hours don’t work? What if I get bored? What if I can
              never come back to critical care in the future because I’ve been
              away from bedside nursing for too long?
            </p>
            <p style={paragraphStyle}>
              However, my first week as a hospice nurse was glorious. As I
              mentioned earlier, I hadn't even realized how bad it was until I
              was out of it. On my first week there was a peculiar moment where
              I was sitting in my car and I noticed sunshine beaming into my
              car. It caused me to look up and that’s when I noticed I was
              parked high on a hill with a stunning ocean view, the smell of the
              sea through the cracked window, and me on my breakfast break that
              I was *actually* taking! It was extremely cathartic. Oh what I had
              been missing! How miserable I was! The nursing care was different
              too. I was able to enjoy *being with,* instead of *doing to,* my
              hospice patients. I returned home from work and had energy to cook
              a real meal and sit down to eat it with my family. Best of all, I
              met what will likely be the best management and work team I will
              ever experience in my life. My drive and passion for being a
              compassionate nurse had been restored. WHY on EARTH had I not made
              this switch sooner?!
            </p>
            <p style={paragraphStyle}>
              So while you may not feel as desperate or unhappy when your in it,
              I implore you to explore some of the symptoms of nurse burnout if
              you feel you could be experiencing any of it at all. Because if
              it’s not discussed openly, it may take you years before you
              realize your actually in it. Don’t settle for this feeling that it
              may just be the way it is in nursing. We have chosen to be nurses
              not to sacrifice ourselves to this profession, but rather to be
              the best versions of ourselves so that we can help others through
              some of their most difficult times.{" "}
            </p>
            <p style={paragraphStyle}>
              If you think you may be experiencing burnout, I encourage you to
              take that leap and take a chance on something better. Remember,
              giving up is the only sure way to fail.{" "}
            </p>
            <p style={paragraphStyle}>
              - Chief Operating Officer CEsimply
            </p>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

const backFormat = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "500",
};

const linkFormat = {
  color: "rgb(35, 81, 133)",
  textDecoration: "none",
  fontWeight: "700",
};

const titleStyle = {
  paddingTop: "2em",
  fontWeight: "700",
};

const subheaderStyle1 = {
  fontSize: "0.9em",
};

const subheaderStyle2 = {
  fontSize: "0.8em",
};

const paragraphStyle = {
  fontSize: "0.7em",
};

export default NursingBurnout;
