import React, { useState, useEffect } from "react";
import "firebase/firestore";
import QRCode from "qrcode";

export const QrCodeGenerator = () => {
  // const exampleQrCodeData = {
  //     "ceSimply":
  //     {
  //         "cePhoto": "",
  //         "ceThumbnail": "",
  //         "completionDate": "11/20/2020",
  //         "hours": 0.5,
  //         "id": "", // will be generated client/app side
  //         "lastEdited": "", // will be generated client/app side
  //         "name": "Improved antimicrobial resistance survey",
  //         "providerNum": "123",
  //         "providerName": "Joint Accreditation"
  //     }
  // }
  let generateQr = () => {
    QRCode.toCanvas(
      document.getElementById("canvas"),
      JSON.stringify({ ceSimply: ceDataForm }),
      (error) => {
        if (error) console.error(error);
      }
    );
  };
  const [ceDataForm, setCeDataForm] = useState({
    // Mandatory
    name: "",
    providerName: "",
    completionDate: "",
    hours: "",
    // Optional
    providerNum: "",
    cePhoto: "",
    ceThumbnail: "",
  });
  const [allErrorMessages, setAllErrorMessages] = useState({
    name: "",
    providerName: "",
    completionDate: "",
    hours: "",
  });
  useEffect(() => {
    // Waits for user to stop typing for 1.5s before generating new QR code
    const delayDebounceFn = setTimeout(() => {
      if (!allMandatoryFieldsFilled()) {
        return;
      }
      if (!allFieldsValid()) {
        return;
      }
      generateQr();
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [JSON.stringify(ceDataForm)]);

  let allFieldsValid = () => {
    let allValid = true;

    // Checking hours
    if (!isNumeric(ceDataForm.hours)) {
      const newData = { hours: "Invalid number" };
      setAllErrorMessages((prevData) =>
        JSON.parse(JSON.stringify({ ...prevData, ...newData }))
      );
      allValid = false;
    } else {
      const newData = { hours: "" };
      setAllErrorMessages((prevData) =>
        JSON.parse(JSON.stringify({ ...prevData, ...newData }))
      );
    }
    return allValid;
  };

  let allMandatoryFieldsFilled = () => {
    // Empty strings are considered false.
    let allFilled = true;
    const mandatoryFields = ["name", "providerName", "completionDate", "hours"];

    for (const field of mandatoryFields) {
      if (!ceDataForm[field]) {
        const newData = { [field]: "Mandatory" };
        setAllErrorMessages((prevData) =>
          JSON.parse(JSON.stringify({ ...prevData, ...newData }))
        );
        allFilled = false;
      } else {
        // Remove error message
        const newData = { [field]: "" };
        setAllErrorMessages((prevData) =>
          JSON.parse(JSON.stringify({ ...prevData, ...newData }))
        );
      }
    }
    return allFilled;
  };

  let handleChange = (event) => {
    const newData = { [event.target.name]: event.target.value };

    // Couple notes: JSON.parse(JSON.stringify( someObject )) is to create a new object.
    // This is necessary to trigger useEffect as otherwise React can't tell the the data has changed
    // {...obj1, ...obj2} is spread syntax to merge two objects
    setCeDataForm((prevData) =>
      JSON.parse(JSON.stringify({ ...prevData, ...newData }))
    );
  };

  let isNumeric = (str) => {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  };

  let fillTodaysDate = () => {
    var d = new Date();
    var dateString = `${("0" + (d.getMonth() + 1)).slice(-2)}/${(
      "0" + d.getDate()
    ).slice(-2)}/${d.getFullYear()}`;
    document.getElementsByName("completionDate").forEach((element) => {
      element.value = dateString;
    });
    // Called because the button doesn't trigger handleChange I guess
    const newData = { completionDate: dateString };
    setCeDataForm((prevData) =>
      JSON.parse(JSON.stringify({ ...prevData, ...newData }))
    );
  };

  return (
    <div>
      <text>Name</text>
      <br />
      <input name="name" onChange={handleChange} />
      <text>{allErrorMessages.name}</text>
      <br />
      <br />

      <text>Provider name</text>
      <br />
      <input name="providerName" onChange={handleChange} />
      <text>{allErrorMessages.providerName}</text>
      <br />
      <br />

      <text>Completion date</text>
      <br />
      <input name="completionDate" onChange={handleChange} />
      <text>{allErrorMessages.completionDate}</text>
      <br />
      <button onClick={fillTodaysDate}>Set Today's Date</button>
      <br />
      <br />

      <text>Hours</text>
      <br />
      <input name="hours" onChange={handleChange} />
      <text>{allErrorMessages.hours}</text>
      <br />
      <br />

      <text>Provider Number</text>
      <br />
      <input name="providerNum" onChange={handleChange} />
      <br />
      <br />

      <canvas id="canvas" align="center" />
    </div>
  );
};

export default QrCodeGenerator;
