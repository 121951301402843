import React from "react";
import ReactDOM from "react-dom";
import { Router, Route } from "react-router-dom";
import history from "./components/tools/History";
import "./App.css";
import App from "./App";
import * as firebase from "firebase";
import * as serviceWorker from "./serviceWorker";
import TagManager from "react-gtm-module";
import ReactGA from 'react-ga';

var firebaseConfig = {
  apiKey: "AIzaSyA8Ys0fgTUMffkRpMeJGbWx7dWAI74ch7g",
  authDomain: "cetracker-2de23.firebaseapp.com",
  databaseURL: "https://cetracker-2de23.firebaseio.com",
  projectId: "cetracker-2de23",
  storageBucket: "cetracker-2de23.appspot.com",
  messagingSenderId: "567629683156",
  appId: "1:567629683156:web:9a207f99b5c6d4da0bde41",
  measurementId: "G-H2MNG10CZC",
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.analytics();
firebase.auth();

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

ReactGA.initialize('AW-11248978233');
ReactGA.pageview(window.location.pathname + window.location.search);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
